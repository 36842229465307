import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Box, Breadcrumbs, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, InputLabel, Link, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import BackToPrevious from '@layouts/BackToPrevious';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const Create = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        username: '',
        plan_id: '',
        admin_remark: '',
        bv: '',
        price: '',
        profit_limit: '',
        service_fee: { type: "", amount: 0 },
        rank_id: '',
        mrank_id: '',
        total_distributions: '',
        percent: '',
        purchase_trade: false,
        split_times: '',
        merchant_id: '',
        // trade_type: 'lock_trade',
    });
    const [plans, setPlans] = useState([]);
    const [rankList, setRankList] = useState({});
    const [mrankList, setMRankList] = useState({});
    const [merchantList, setMerchantList] = useState({});

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading, getConfirmation } = useNotificationLoading();
    const navigate = useNavigate();
    // const tradeList = [{ value: "auto_sell", label: t('planHistories.autoSell') }, { value: "lock_trade", label: t('planHistories.lockTrade') }];

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === 'purchase_trade') {
            setState({ ...state, purchase_trade: target.checked ? 1 : 0 });
        } else if (name === 'type' || name === 'amount') {
            setState({ ...state, service_fee: { ...state.service_fee, [name]: target.value } });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const submitData = async () => {
        const confirmation = await getConfirmation();
        if(confirmation.status){
            setLoading(true);
            setInputErrors();
            let apiData = {
                username: state.username,
                plan_id: state.plan_id,
                admin_remark: state.admin_remark,
                bv: state.bv,
                price: state.price,
                profit_limit: state.profit_limit,
                service_fee: state.service_fee,
                rank_id: state.rank_id,
                mrank_id: state.mrank_id,
                total_distributions: state.total_distributions,
                percent: state.percent,
                purchase_trade: state.purchase_trade,
                split_times: state.split_times,
                merchant_id: state.merchant_id,
                audit_remark: confirmation.note
                // auto_sell: (state.purchase_trade && state.trade_type === 'auto_sell') ? true : false,
                // lock_trade: (state.purchase_trade && state.trade_type === 'lock_trade') ? true : false,
            }
            postUrl(`/admin-user-plan`, apiData).then(response => {
                setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/plan-history-edit/${data.id}`, { replace: true });
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    useEffect(() => {
        setLoading(true);
        getUrl(`/active-plans`).then(response => {
            setLoading(false);
            if (response.status) {
                setPlans(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        getUrl(`/active-ranks`).then(response => {
            setLoading(false);
            if (response.status) {
                setRankList(response.data);
                setMRankList(response.mrank);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        getUrl(`/admin/active-merchants`).then(response => {
            setLoading(false);
            if (response.status) {
                setMerchantList(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let plan = _.find(plans, function (p) { return parseInt(p.id) === parseInt(state.plan_id); });
        if (plan) {
            setState({
                ...state,
                bv: plan.bv,
                price: plan.price,
                profit_limit: plan.profit_limit,
                service_fee: plan.service_fee,
                rank_id: plan.rank_id ? plan.rank_id : '',
                mrank_id: plan.mrank_id ? plan.mrank_id : '',
                total_distributions: plan.roi?.total_distributions,
                percent: plan.roi?.percent,
                split_times: plan.extra_setting?.split_times || 0,
                merchant_id: plan.extra_setting?plan.extra_setting.merchant_id?.[[0]] : '',
            })
        }
        // eslint-disable-next-line
    }, [state.plan_id])

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.planHistoryAdd`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/plan-histories`}>
                            {t(`title.planHistory`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.planHistoryAdd`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.username`)}
                            variant="outlined"
                            value={state.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                        <Typography variant="caption">use comma (,) to separate multitple usernames</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`planHistories.plan`)}</InputLabel>
                            <Select
                                value={state.plan_id}
                                label={t(`planHistories.plan`)}
                                name="plan_id"
                                onChange={({ target }) => setState({ ...state, plan_id: target.value })}
                            >
                                {_.map(plans, plan => {
                                    return <MenuItem key={plan.id} value={plan.id}>{t(plan.name[i18n.language])}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`planHistories.adminRemark`)}
                            variant="outlined"
                            value={state.admin_remark}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.admin_remark ? inputErrors.admin_remark : ''}
                            error={inputErrors && inputErrors.admin_remark ? true : false}
                            name="admin_remark"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" style={{ marginLeft: 10 }}>{t('planHistories.planDetails')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.bv`)}
                            variant="outlined"
                            value={state.bv}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bv ? inputErrors.bv : ''}
                            error={inputErrors && inputErrors.bv ? true : false}
                            name="bv"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.price`)}
                            variant="outlined"
                            value={state.price}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.price ? inputErrors.price : ''}
                            error={inputErrors && inputErrors.price ? true : false}
                            name="price"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.profitLimit`)}
                            variant="outlined"
                            value={state.profit_limit}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_limit ? inputErrors.profit_limit : ''}
                            error={inputErrors && inputErrors.profit_limit ? true : false}
                            name="profit_limit"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.percent`)}
                            variant="outlined"
                            value={state.percent}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.percent ? inputErrors.percent : ''}
                            error={inputErrors && inputErrors.percent ? true : false}
                            name="percent"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.total_distributions`)}
                            variant="outlined"
                            value={state.total_distributions}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.total_distributions ? inputErrors.total_distributions : ''}
                            error={inputErrors && inputErrors.total_distributions ? true : false}
                            name="total_distributions"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`plans.type`)}</InputLabel>
                            <Select
                                value={state.service_fee?.type || ""}
                                label={t(`plans.type`)}
                                name="type"
                                onChange={handleChange}
                                error={inputErrors && inputErrors?.['service_fee.type'] ? true : false}
                            >
                                <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                {_.map([
                                    { 'code': 'fixed', 'label': t(`plans.typeFixed`) },
                                    { 'code': 'percent', 'label': t(`plans.typePercent`) }
                                ], serviceFeeType => {
                                    return <MenuItem key={serviceFeeType.code} value={serviceFeeType.code}>{serviceFeeType.label}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors?.['service_fee.type'] ? inputErrors?.['service_fee.type'] : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.amount`)}
                            variant="outlined"
                            value={state?.service_fee?.amount}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors?.['service_fee.amount'] ? inputErrors?.['service_fee.amount'] : ''}
                            error={inputErrors && inputErrors?.['service_fee.amount'] ? true : false}
                            name="amount"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{ state?.service_fee?.type === 'percent' ? '%' : '' }</InputAdornment>,
                            }}
                            onChange={handleChange}
                        />
                        {
                            state?.service_fee?.type === 'percent' &&
                            <Typography variant="caption">{t('plans.calculateAmount', {value: (state.price*(state.service_fee?.amount/100) || 0).toFixed(2)})}</Typography>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`plans.rankId`)}</InputLabel>
                            <Select
                                value={state.rank_id}
                                label={t(`plans.rankId`)}
                                name="rank_id"
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                {_.map(rankList, rank => {
                                    return <MenuItem key={rank.id} value={rank.id}>{rank?.name ? rank?.name?.[i18n.language] : rank?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                {
                    _.size(mrankList) > 0
                        ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.mrankId`)}</InputLabel>
                                    <Select
                                        value={state.mrank_id}
                                        label={t(`plans.mrankId`)}
                                        name="mrank_id"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map(mrankList, rank => {
                                            return <MenuItem key={rank.id} value={rank.id}>{rank?.name ? rank?.name?.[i18n.language] : rank?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                }
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`plans.merchant`)}</InputLabel>
                            <Select
                                value={state.merchant_id}
                                label={t(`plans.merchant`)}
                                name="merchant_id"
                                onChange={handleChange}
                                error={inputErrors && inputErrors?.merchant_id ? true : false}
                            >
                                <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                {_.map(merchantList, merchant => {
                                    return <MenuItem key={merchant.id} value={merchant.id}>{merchant?.name ? merchant?.name?.[i18n.language] : merchant?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors?.merchant_id ? inputErrors?.merchant_id : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" style={{ marginLeft: 10 }}>{t('planHistories.trade')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth style={{ alignItems: "flex-start" }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.purchase_trade ? true : false}
                                        onChange={handleChange}
                                        name="purchase_trade"
                                    />
                                }
                                labelPlacement="start"
                                label={t(`planHistories.purchase_trade`)}
                            />
                            {
                                inputErrors && inputErrors.purchase_trade
                                    ?
                                    <Typography variant="body2" style={{ marginLeft: "16px", color: theme.palette.indicator.red }}>{inputErrors.purchase_trade}</Typography>
                                    :
                                    null
                            }
                        </FormControl>
                    </Box>
                </Grid>
                {
                    state.purchase_trade
                        ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`planHistories.split_times`)}
                                    variant="outlined"
                                    value={state.split_times}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.split_times ? inputErrors.split_times : ''}
                                    error={inputErrors && inputErrors.split_times ? true : false}
                                    name="split_times"
                                    type="number"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        :
                        null
                }
                {/* {
                    state.purchase_trade
                        ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl>
                                    <FormLabel>{t(`planHistories.trade_type`)}</FormLabel>
                                    <RadioGroup
                                        defaultValue="lock_trade"
                                        name="trade_type"
                                        onChange={handleChange}
                                    >
                                        {
                                            _.map(tradeList, type => {
                                                return (
                                                    <FormControlLabel key={type.value} value={type.value} control={<Radio />} label={type.label} />
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                } */}
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/plan-histories`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default Create
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

import { EditNote } from '@mui/icons-material';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/report-merchant-transaction-list`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.data);
                console.log(data)
                setRowCount(response.data.total);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const columns = useMemo(() => ([
        {
            header: `${t(`general.id`)}`,
            id: 'id',
            accessorFn: (row) => (row?.id ? row?.id : '-'),
        },
        {
            header: `${t(`merchant.code`)}`,
            id: 'merchant.code',
            accessorFn: (row) => (row?.merchant?.code ? row?.merchant?.code : '-'),
            enableSorting: false,
        },
        {
            header: `${t(`merchant.name`)}`,
            id: 'merchant.name',
            enableSorting: false,
            accessorFn: (row) => (i18n.language === 'cn' ? row?.merchant?.name?.cn : row?.merchant?.name?.en),
        },        
        {
            header: `${t(`general.ulid`)}`,
            id: 'ulid',
            enableSorting: false,
            accessorFn: (row) => (row?.trade_transaction?.ulid ?  row?.trade_transaction?.ulid : '-'),
        },
        {
            // use for export, hide for display
            header: `${t(`trades.value_code`)}`,
            accessorKey: 'transaction_code_display.' + i18n.language,
        },
        {
            // use for display, hide for export
            header: `${t(`trades.value_code`)}`,
            accessorKey: 'transaction_code',
            Cell: ({ cell }) => (cell?.row?.original?.transaction_code_display && (cell?.row?.original?.transaction_code_display[i18n.language] ? cell?.row?.original?.transaction_code_display[i18n.language] : cell?.row?.original?.transaction_code_display?.en)),
            enableSorting: false,
            enableColumnFilter: false,
            hideExport: true,
        },
        {
            header: `${t(`user.username`)}`,
            id: 'wallet_balanceable.username',
            accessorFn: (row) => (row.trade_transaction?.trade_profile?.user?.username ? row.trade_transaction?.trade_profile?.user?.username : '-'),
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: `${t(`trades.rate`)}`,
            id: 'price',
            accessorFn: (row) => (row?.price ? parseFloat(row?.price).toFixed(3) : '-'),
        },
        {
            header: `${t(`trades.spendValue`)}`,
            id: 'value',
            accessorFn: (row) => (row?.value ? parseFloat(row?.value).toFixed(2) : '-'),
        },
        {
            header: `${t(`trades.active_unit`)}`,
            id: 'trade_transaction.active_unit',
            enableSorting: false,
            accessorFn: (row) => (row.trade_transaction?.active_unit ? row.trade_transaction?.active_unit : '-'),
        },
        {
            header: `${t(`trades.passive_unit`)}`,
            id: 'trade_transaction.passive_unit',
            enableSorting: false,
            accessorFn: (row) => (row.trade_transaction?.passive_unit ? row.trade_transaction?.passive_unit : '-'),
        },
        {
            header: `${t(`trades.totalTrade`)}`,
            id: 'unit',
            accessorFn: (row) => (row?.unit ? row?.unit : '-'),
        },
        {
            header: `${t(`general.updatedAt`)}`,
            accessorKey: 'updated_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.reportMerchantTransaction`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.reportMerchantTransaction`)}</Typography>
                </Breadcrumbs>
            </Grid>
            {
                _.includes(permissions, `merchant-transaction-create`) 
                ?
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ paddingBottom: 5 }}>
                        <Box display="flex" alignItems="center" >
                            <Link underline='none' to={`/merchant-transaction-adjustment`} component={RouterLink}>
                                <IconButton style={{ color: theme.palette.button.main, border: `2px solid ${theme.palette.button.main}`, borderRadius: 5, padding: 0, marginRight: 5 }}>
                                    <EditNote style={{ fontSize: 20 }} />
                                </IconButton>
                            </Link>
                            <Typography>{t(`title.merchantTransactionAdd`)}</Typography>
                        </Box>
                    </Grid>
                : null
            }
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'MerchantTransactionList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['transaction_code_display.' + i18n.language]: false }}
            />
        </>
    )
}

export default Listing
import _ from 'lodash';

import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams, useNavigate } from 'react-router-dom';

import { 
    Box, 
    Breadcrumbs, 
    Button, 
    FormControl, 
    Grid, 
    InputLabel, 
    Link, 
    MenuItem, 
    Select, 
    TextField, 
    Typography, 
    FormHelperText
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl , putUrl } from '@utils/ApiAction';
import { buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';

const MODULE = 'trades';

const INITIAL_STATE = {
    price: 0, 
    max_unit: 0, 
    status: '',
}

export default function Detail() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const location = useLocation();
    const formType = location.pathname === `/trade-add` ? "add" : "edit";

    let navigate = useNavigate();
    let { id } = useParams();

    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [statusListing, setStatusListing] = useState({});
    const [basicTrade, setBasicTrade] = useState(0);
    const [autoSellTrade, setAutoSellTrade] = useState(0);

    useEffect(() => {
        setLoading(true);
        callApi()

        // eslint-disable-next-line
    }, []);

    const callApi = () => {
        if (id){
            getUrl(`/${MODULE}/${id}`).then(response => {
                if (response.status) {
                    setState(response?.data);
                    setBasicTrade(response?.basic_trade);
                    setAutoSellTrade(response?.auto_sell_trade);
                    setStatusListing(response?.status_list);
                }else{
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors({});
        let postData = {
            max_unit: state.max_unit,
            status: state.status
        };
    
        putUrl(`/${MODULE}/${id}`, postData).then(response => {
            if (response.status) {
                callApi()
                addAlert('', t('success.createSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        })
        .catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        .finally(() => {
            setLoading(false);
        })
        ;
    }

    return (
        <>
            <BackToPrevious />

            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>

                <Grid item xs={12} sm={4} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.basic_trade`)}
                            variant="outlined"
                            value={ basicTrade }
                            disabled={true}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.auto_sell_trade`)}
                            variant="outlined"
                            value={ autoSellTrade }
                            disabled={true}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.totalTrade`)}
                            variant="outlined"
                            value={ basicTrade + autoSellTrade }
                            disabled={true}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.price`)}
                            variant="outlined"
                            value={ state?.price }
                            disabled={true}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.max_unit`)}
                            variant="outlined"
                            onChange={handleChange}
                            type='number'
                            name='max_unit'
                            value={ state?.max_unit }
                            helperText={inputErrors && inputErrors.max_unit ? inputErrors.max_unit : ""}
                            error={inputErrors && inputErrors.max_unit ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`${MODULE}.status`)}</InputLabel>
                            <Select
                                value={state.status}
                                label={t(`${MODULE}.status`)}
                                name="status"
                                onChange={({ target }) => setState({ ...state, status: target.value })}
                                disabled
                            >
                                {_.map(statusListing, (data , key) => {
                                    return <MenuItem key={key} value={key}>{data[i18n.language]}</MenuItem>
                                })}
                            </Select>
                            {
                                inputErrors && inputErrors?.status &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.status}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    rightIcon: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "green",
        cursor: 'pointer'
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    },
    leftIcon: {
        position: "absolute",
        left: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    }
}))
import _ from 'lodash';
import BackToPrevious from '@layouts/BackToPrevious';
import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import { buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";

import { IoClose } from "react-icons/io5";
import BasicInfo from './BasicInfo';
import EditAddress from './EditAddress';
import SupportDocument from './SupportDocument';
import ExtraSetting from './ExtraSetting';
import StrategiesSetting from './StrategiesSetting';

const MODULE = 'merchant';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const INITIAL_STATE = {
    description: '',
    name: { en: "", cn: "" },
    status: ``,
    mobile: '',
    website: '',
    email: '',
    company_register_number: '',
    bussiness_nature: '',
    about_shop: '',
    product_sell: '',
    code: '',
    user_id: '',
    merchant_type: 0,
    remark_log: {status: '', remark: '', date_time: ''},
    extra_setting: { convert: { available: true } }
}

const MerchantDetail = () => {
    let { id } = useParams();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [tabValue, setTabValue] = useState(0);
    const [currentMerchantType, setCurrentMerchantType] = useState(0);
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading } = useNotificationLoading();
    const [statusList, setStatusList] = useState([]);
    const [qrDialog, setQrDialog] = useState(false);
    const componentRef = useRef(null)
    const [userList, setUserList] = useState({});
    const [merchantType, setMerchantType]= useState({});
    const formType = "edit";
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'QR_',
    });
    const [inputErrors, setInputErrors] = useState({});

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: "",
        code: "",
        mobile: "",
        error: {},
    })

    useEffect(() => {
        setLoading(true);
        getUrl(`/mobile-code-list`).then(response => {
            setLoading(false);
            if (response.status) {
                setMobileCodeList({ country: response?.data?.country_list, code: {} });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList(prevList => ({ ...prevList, code: codeList }));
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const callApi = () =>{
        setLoading(true);
        getUrl(`/merchants/${id}`).then(response => {
            if (response.status) {
                const merchantData = response.data;
                setState({
                    ...merchantData,
                    remark_log: response.remark,
                });
                setUserMobile({
                    ...userMobile,
                    country: '',
                    code: merchantData.mobile_country_code,
                    mobile: merchantData.mobile,
                });
                setCurrentMerchantType(merchantData?.merchant_type);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })
        ;
    }
    useEffect(() => {
        if (formType === 'edit' && id) {
            callApi();
        } 
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        let param = {
            merchant_id: id
        }
        getUrl(`/merchants-options`, param).then(response => {
            if (response.status) {
                setStatusList(response.data);
                setUserList({ ...userList, ...response.users });
                setMerchantType(response.type)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const ComponentToPrint = ({ innerRef }) => {
        return (

            <div style={{ height: '100%', width: "100%", backgroundColor: 'white', paddingTop: '10%', backgroundSize: '100%' }} ref={innerRef}>
                {/* <Box style={{width: "100%", display: "flex", justifyContent: "center",  paddingBottom: 20}}>
                    <Typography style={{fontWeight: "bold", fontSize: '50px'}} align="center">Redeem With</Typography>
                </Box> */}

                <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <img alt='logo' src="../logo512.png" style={{ width: "25%" }} />
                </Box>

                <Box style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                    <QRCodeSVG
                        size='300'
                        style={{ backgroundColor: 'white', padding: 30, border: 'none', borderRadius: '18px', boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.15) inset, 6px 6px 6px 0px rgba(0, 0, 0, 0.11)' }}
                        value={`${process.env.REACT_APP_MEMBER_SIDE_URL}spend?m=${btoa(id)}`}
                    />
                </Box>

                <Box style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: 40 }}>
                    <Typography style={{ fontWeight: "bold", fontSize: '55px' }} align="center">{state?.name[i18n.language] ? state?.name[i18n.language] : state?.name['en']}</Typography>
                </Box>

                {/* 
                <Box style={{width: "100%", display: "flex", justifyContent: "center",  paddingBottom: 60}}>
                    <Typography style={{  fontSize: '21px'}} align="center">{branchInfo.branch_name}</Typography>
                </Box> */}

            </div>

        )
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let updateData = {
            name: state.name,
            user_id: state.user_id,
            country: userMobile?.country,
            mobile_code: userMobile?.code,
            mobile: userMobile?.mobile,
            email: state.email,
            website: state.website,
            description: state.description,
            company_register_number: state.company_register_number,
            bussiness_nature: state.bussiness_nature,
            about_shop: state.about_shop,
            product_sell: state.product_sell,
            status: state.status,
            new_logo: state.new_logo,
            merchant_type: state.merchant_type,
            code: state.code,
            remark_log: state.remark_log,
            _method: 'put',
        }

        const formData = new FormData();
        buildFormData(formData, updateData);

        postUrl(`/merchants/${id}`, formData).then(response => {
            setLoading(false);
            const { status, errors } = response;
            if (status) {
                callApi();
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', response?.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            callApi();
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleValueChange = ({ target }) => {
        let { name, value, files } = target;
        if (name === 'name.en' || name === 'name.cn') {
            const lang = name.split('.')[1]; // Extract language from the field name
            setState({ ...state, name: { ...state.name, [lang]: value } });
        } else if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            setUserMobile({ ...userMobile, mobile: value });
            return;
        } else if (name === 'country') {
            setUserMobile({ ...userMobile, country: value });
            return;
        } else if (name === 'user_id') {
            setState({ ...state, 'user_id': value });
        } else if (name === 'new_logo') {
            if (files[0]?.size > 5242880) {
                target.value = null;
                setState({ ...state, new_logo: null })
                setInputErrors({ 'new_logo': t('general.maxFileSize', { S: 5120 }) })
                addAlert("", t('general.maxFileSize', { S: 5120 }), 'error', '');
            } else {
                setInputErrors({})
                setState({ ...state, new_logo: files[0] })
            }
        } else if (name === 'status') {
            setState({ ...state, status: value, remark_log: {...state.remark_log, status: value} });
        } else {
            setState({ ...state, [name]: value });
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_MEMBER_SIDE_URL}spend?m=${btoa(id)}`)
          .then(() => {
            addAlert('', t('success.copySuccess'), 'success', '');
          })
          .catch(err => {
            addAlert('',  err?.message || t('error.copyFailed'), 'error', '');
          });
      };

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.merchant${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                    {
                        state?.status === 20 &&
                        <Button variant="contained" style={{ marginLeft: 10 }} size='small' onClick={() => setQrDialog(!qrDialog)}>{t('merchant.qrCode')}</Button>
                    }
                </div>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/merchant`}>
                            {t(`title.merchant`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.merchantEdit`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label={t(`${MODULE}.basicInfo`)} {...a11yProps(0)} value={0} />
                    <Tab label={t(`${MODULE}.editAddress`)} {...a11yProps(1)} value={1} />
                    <Tab label={t(`${MODULE}.supporDoc`)} {...a11yProps(2)} value={2} />
                    <Tab label={t(`${MODULE}.extraSetting`)} {...a11yProps(3)} value={3} />
                    {
                        parseInt(currentMerchantType) === 2 &&
                            <Tab label={t(`${MODULE}.strategiesSetting`)} {...a11yProps(4)} value={4} />
                    }
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <BasicInfo merchantInfo={state} statusList={statusList} setState={setState} submitData={submitData} handleChange={handleValueChange} inputErrors={inputErrors} userList={userList} merchantType={merchantType} mobileCodeList={mobileCodeList} userMobile={userMobile}/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <EditAddress />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <SupportDocument />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <ExtraSetting merchantInfo={state} callApi={callApi} />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
                <StrategiesSetting merchantInfo={state} callApi={callApi} />
            </TabPanel>
            <Dialog maxWidth="sm" open={qrDialog} onClose={() => setQrDialog(!qrDialog)} fullWidth>
                <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>{t(`merchant.qrCode`)}</div>
                    <IconButton onClick={() => setQrDialog(!qrDialog)}><IoClose /></IconButton>
                </DialogTitle>
                <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <QRCodeSVG
                        size='180'
                        value={`${process.env.REACT_APP_MEMBER_SIDE_URL}spend?m=${btoa(id)}`}
                    />
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,paddingBottom:25 }}>
                    <Button variant="contained" style={{ marginLeft: 10 }} size='small' onClick={handlePrint}>{t('button.print')}</Button>
                    <Button variant="outlined" style={{ marginLeft: 10 }} size='small' onClick={copyToClipboard}>{t('button.copyToClipboard')}</Button>
                    <div style={{ display: "none" }}>
                        <ComponentToPrint innerRef={componentRef} />
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MerchantDetail;
import React, { useState , useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams , useSearchParams , useNavigate} from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { postUrl, getUrl } from '@utils/ApiAction';

import { Link, TextField, Box, Button, Breadcrumbs, Grid, Typography, InputAdornment, IconButton , FormControl , InputLabel , Select , MenuItem , FormHelperText } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import BackToPrevious from '@layouts/BackToPrevious';

const MODULE = 'user';
const INITIAL_STATE = {
    username: '',
    name: '',
    email: '',
    referralName:'',
    placementMode:'',
    placementName:'',
    placementPosition:1,
    password: '',
    passwordConfirmation: '',
    roleType: ['member'],
}

const Create = () => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [showPassword, setShowPassword] = useState({
        password: false,
        passwordConfirmation: false,
    });
    const [state, setState] = useState(INITIAL_STATE);
    const { addAlert, setLoading, getConfirmation } = useNotificationLoading();
    const POSITION = [
        { 'code': 'left', 'value': 1, label: t('general.left') },
        { 'code': 'right', 'value': 2, label: t('general.right') }
    ];
    const [searchParams] = useSearchParams();
    const PLACEMENT_MODE = [
        { 'code': 'no', 'value': 'no', label: t('user.pMode.no') },
        { 'code': 'auto', 'value': 'auto', label: t('user.pMode.auto') },
        { 'code': 'manual', 'value': 'manual', label: t('user.pMode.manual') }
    ];

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [userMobile, setUserMobile] = useState({
        country: "",
        code: "",
        mobile: "",
        error: {},
    })

    useEffect(() => {
        setLoading(true);
        getUrl(`/mobile-code-list`).then(response => {
            setLoading(false);
            if (response.status) {
                setMobileCodeList({ country: response?.data?.country_list, code: {} });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (_.size(mobileCodeList?.country) > 0) {
            if (userMobile?.country !== '') {
                let codeList = {};
                let countryCode = userMobile?.code;
                let code = _.find(mobileCodeList?.country, { 'code': userMobile?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (userMobile?.code !== '' ? userMobile?.code : '');
                    }
                }

                setMobileCodeList(prevList => ({ ...prevList, code: codeList }));
                setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (userMobile?.country === '' && userMobile?.code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, userMobile?.code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setUserMobile(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
    }, [mobileCodeList?.country, userMobile?.country, userMobile?.code]);
    
    useEffect (() => {
        setState({
            ...state,
            placementName: searchParams.get('placement') ? searchParams.get('placement') : '',
            referralName: searchParams.get('placement') ? searchParams.get('placement') : '',
            placementPosition: searchParams.get('position') ? searchParams.get('position') : '',
        });
        // eslint-disable-next-linex
    }, [searchParams]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            setUserMobile({ ...userMobile, [name]: value });
            return;
        }
        if (name === 'username') {
            value = value.replace(/[^\w\s]/gi, "").toLowerCase();
        }
        setState({ ...state, [name]: value });
    };

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const submitData = async () => {
        const confirmation = await getConfirmation();
        if(confirmation.status){
            setLoading(true);
            setInputErrors();
            let params = {
                username: state.username,
                name: state.name,
                email: state.email,
                country: userMobile?.country,
                mobile_code: userMobile?.code,
                mobile: userMobile?.mobile,
                password: state.password,
                password_confirmation: state.passwordConfirmation,
                role_type: state.roleType,
                referral_name:state?.referralName,
                placement_mode: state?.placementMode,
                placement_name: state?.placementMode === 'manual' ? state?.placementName : '',
                placement_position: state?.placementMode === 'manual' ? (state?.placementPosition?state?.placementPosition:'') : '',
                audit_remark: confirmation.note
            }
            postUrl(`/members`, params).then(response => {
                setLoading(false);
                if (response.status) {
                    setState(INITIAL_STATE);
                    setUserMobile({
                        country: "",
                        code: "",
                        mobile: "",
                        error: {}
                    });
                    addAlert('', t('success.createSuccess'), 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.memberAdd`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/members`}>
                            {t(`title.member`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.memberAdd`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.username`)}
                            variant="outlined"
                            value={state.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                        <Typography variant="caption" color="textSecondary" style={{ display: 'flex', textAlign: 'left' }}>
                            {t('user.usernameLowerCase')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['country'] ? true : false}>
                            <InputLabel>{t(`${MODULE}.country`)}</InputLabel>
                            <Select
                                value={userMobile.country || ''}
                                label={t(`${MODULE}.country`)}
                                name="country"
                                onChange={({ target }) => setUserMobile({ ...userMobile, country: target.value })}
                                displayEmpty
                            >
                                <MenuItem key={0} value={0}>
                                    --- {`${t('general.pleaseSelect')} ${t(`${MODULE}.country`)} `} ---
                                </MenuItem>
                                {
                                    _.map(mobileCodeList.country, (data, key) => {
                                        const countryName = i18n.language === 'cn' ? data.name_cn : data.name;
                                        return (
                                            <MenuItem key={key} value={data.code}>
                                                {countryName}
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors['country'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['country']}</FormHelperText>
                            }
                            <Typography variant="caption" color="textSecondary" style={{ display: 'flex', textAlign: 'left' }}>{t('general.pleaseSelectCountry')}</Typography>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['mobile_code'] ? true : false}>  
                            <InputLabel>{t(`${MODULE}.mobileCode`)}</InputLabel> 
                            <Select
                                value={userMobile.code || ''}
                                label={t(`${MODULE}.mobileCode`)}
                                name="mobileCode"
                                onChange={({ target }) => setUserMobile({ ...userMobile, code: target.value })}
                                displayEmpty
                            >
                                {/* <MenuItem value="">
                                    {``}
                                </MenuItem> */}
                                {_.size(mobileCodeList?.code) > 0 ? (
                                    _.map(mobileCodeList?.code, (option, key) => (
                                        <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                            {option}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" style={{ color: '#222' }}>
                                        {``}
                                    </MenuItem>
                                )}
                            </Select>
                            {
                                inputErrors && inputErrors['mobile_code'] &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors['mobile_code']}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.mobile`)}
                            variant="outlined"
                            value={userMobile.mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.email`)}
                            variant="outlined"
                            type="email"
                            value={state.email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            variant="outlined"
                            name='referralName'
                            label={t(`${MODULE}.referralName`)}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                            error={inputErrors && inputErrors.referral_name ? true : false}
                            InputLabelProps={{ shrink: true }}
                            value={state.referralName}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth error={inputErrors && inputErrors.placement_mode ? true : false}>
                            <InputLabel>{t(`${MODULE}.placementMode`)}</InputLabel>
                            <Select
                                value={state?.placementMode || ''}
                                label={t(`${MODULE}.placementMode`)}
                                name="placementMode"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} value={0}>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.placementMode`)} `} --- </MenuItem>
                                {
                                    _.map(PLACEMENT_MODE, (data, key) => {
                                        return <MenuItem key={key} value={data.value}>{data.label}</MenuItem>
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors?.placement_mode &&
                                <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors?.placement_mode}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>
                {
                    state.placementMode === 'manual'
                        ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                                <TextField
                                    variant="outlined"
                                    name='placementName'
                                    label={t(`${MODULE}.placementName`)}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.placement_name ? inputErrors.placement_name : ''}
                                    error={inputErrors && inputErrors.placement_name ? true : false}
                                    InputLabelProps={{ shrink: true }}
                                    value={state.placementName}
                                />
                            </Box>
                        </Grid>
                        :
                        null

                }
                {
                    state.placementMode === 'manual' && _.size(POSITION) > 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                        <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth error={inputErrors && inputErrors.placement_position ? true : false}>
                                <InputLabel>{t(`${MODULE}.placementPosition`)}</InputLabel>
                                <Select
                                    value={state?.placementPosition || ''}
                                    label={t(`${MODULE}.placementPosition`)}
                                    name="placementPosition"
                                    onChange={handleChange}
                                    displayEmpty
                                >
                                    <MenuItem key={0} value={0}>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.placementPosition`)} `} --- </MenuItem>
                                    {
                                        _.map(POSITION, (data, key) => {
                                            return <MenuItem key={key} value={data.value}>{data.label}</MenuItem>
                                        })
                                    }
                                </Select>
                                {
                                    inputErrors && inputErrors?.placement_position &&
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors?.placement_position}</FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="password"
                            label={t(`${MODULE}.password`)}
                            type={showPassword.password ? 'text' : 'password'}
                            value={state.password}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('password')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.password ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate autoComplete="on" sx={{ '& > :not(style)': { m: 1, width: '100%' } }}>
                        <TextField
                            name="passwordConfirmation"
                            label={t(`${MODULE}.confirmPassword`)}
                            type={showPassword.passwordConfirmation ? 'text' : 'password'}
                            value={state.passwordConfirmation}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('passwordConfirmation')}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword.passwordConfirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/members`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.save')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default Create;
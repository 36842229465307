import React, { useLayoutEffect, useRef, forwardRef, useEffect } from 'react';
import { OrgChart } from 'd3-org-chart';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import './css/tree.css';

export const OrgChartComponent = forwardRef((props, ref) => {
    let chart = null;

    const { t, i18n } = useTranslation();
    const d3Container = useRef();

    // We need to manipulate DOM
    useLayoutEffect(() => {

        if (props.data && d3Container?.current) {

            if (!chart) {
                chart = new OrgChart();
            }
            chart
            .container(d3Container?.current)
            .data(props.data)
            .childrenMargin(d => props.displayType === 'downlines' ? 50 : 80)
            .compactMarginBetween(d => 25)
            .compactMarginPair(d => 50)
            .neightbourMargin((a, b) => 25)
            .siblingsMargin(d => 25)
            .onNodeClick((d, i, arr) => {
                props.onNodeClick(d);
            })
            .layout('top')
            .nodeContent(function(d, i, arr, state) {

                const ref = _.size(d.data) ? d.data.placement_username : null;
                if(_.size(d.data.user)) {
                    let rank = _.size(d.data.user.max_rank) ? d.data.user.max_rank.name : null;
                    if(rank) {
                        rank = _.split(rank, '|');
                        rank = i18n.language === "cn" && rank[1] ? rank[1] : rank[0];
                    } else {
                        rank = "-";
                    }
                    const expiry_date = _.size(d.data.user.expiry_date) ? new Date(d.data.user.expiry_date.replace(/-/g, "/")).toLocaleDateString() : "-";
                    const placement_group_sales1 = _.size(d.data.placement_group_sales1) ? Math.trunc(d.data.placement_group_sales1) : "0";
                    const placement_group_sales2 = _.size(d.data.placement_group_sales2) ? Math.trunc(d.data.placement_group_sales2) : "0";
                    const rank_code = _.size(d.data.user.max_rank) ? d.data.user.max_rank.code : '0';
                    const carried_forward1 = _.size(d.data.latest_vol1) ? Math.trunc(d.data.latest_vol1) : "0";
                    const carried_forward2 = _.size(d.data.latest_vol2) ? Math.trunc(d.data.latest_vol2) : "0";
                    const today1 = _.size(d.data.today_vol1) ? Math.trunc(d.data.today_vol1) : "0";
                    const today2 = _.size(d.data.today_vol2) ? Math.trunc(d.data.today_vol2) : "0";
                    // backup 
                    // const carried_forward1 = _.size(d.data.vol1_aft) ? Math.trunc(d.data.vol1_aft) : "0";
                    // const carried_forward2 = _.size(d.data.vol2_aft) ? Math.trunc(d.data.vol2_aft) : "0";
                    // const today1 = _.size(d.data.vol1) ? Math.trunc(d.data.vol1) : "0";
                    // const today2 = _.size(d.data.vol2) ? Math.trunc(d.data.vol2) : "0";
                    
                    let returnDiv = `<div class="tree-container" style="min-width:${d.width}px;min-height:${d.height}px;pointer-events:auto;"><div class="tree-avatar-container">`;
                    if(i === 0 && ref) {
                        returnDiv += `<a class="up-arrow-container" href="/placement-genealogy2?username=${ref}" style="pointer-events:auto">☝️</a>`
                    }
                    // <img class="tree-avatar" src="../images/rank_logo/rank-${rank_code}.png" alt="rank logo" />
                    returnDiv += `
                        </div>
                            <div class="tree-content-container" style="background:${d.data.username === props.searchUsername ? '#f1f1f1' : 'transparent'}">
                                <span class="title-username">${d.data.username}</span>
                                <div class="cf-container">Accumulated Group BV</div>
                                <div class="display-container">
                                    <div class="left-right-container">
                                        ${placement_group_sales1}
                                    </div>
                                    <div class="left-right-container">
                                        ${placement_group_sales2}
                                    </div>
                                </div>
                                <div class="cf-container">Carried Forward</div>
                                <div class="display-container">
                                    <div class="left-right-container">
                                        ${carried_forward1}
                                    </div>
                                    <div class="left-right-container">
                                        ${carried_forward2}
                                    </div>
                                </div>
                                <div class="cf-container">Today Group BV</div>
                                <div class="display-container">
                                    <div class="left-right-container">
                                        ${today1}
                                    </div>
                                    <div class="left-right-container">
                                        ${today2}
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;

                    return returnDiv;
                } else {
                    if (props.displayType === 'downlines') {
                        return `
                            <a href="/member-add?placement=${d.data.placement_username}&position=${d.data.position}" class="tree-container" style="min-width:${d.width}px;min-height:${d.height}px;text-decoration:none;color:black;pointer-events:auto">
                                <div class="tree-avatar-container">
                                    <div class="tree-avatar" style="background-color:gray"></div>
                                </div>
                                <div class="tree-content-container">
                                    <span class="title-username">Empty - [ ${d.data.position === 1 ? 'L' : 'R'} ]</span>
                                    <div class="display-container">
                                        <div class="left-right-container">
                                            0
                                        </div>
                                        <div class="left-right-container">
                                            0
                                        </div>
                                    </div>
                                    <div class="empty-container">
                                        [ + ]
                                    </div>
                                </div>
                            </a>
                        `;
                    } else {
                        return `
                            <div style="min-height:100%;" class="tree-content-container">
                                <p style="font-size:40px;">...</p>
                            </div>
                        `
                    }
                }
            })
            .render();
        }
    }, [props.data, d3Container.current]);

    useEffect(() => {
        chart.expandAll().fit();  
    }, [chart]);

    return (
        <div>
            <div ref={d3Container} />
        </div>
    );
});
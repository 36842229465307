import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

const Listing = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
        });
    const factorList = [{ text: t('wallets.add'), value: 1 }, { text: t('wallets.deduct'), value: -1 }];
    const [codeList, setCodeList] = useState([]);
    const [mapCodeList, setMapCodeList] = useState([]);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/trade-redeemable-transaction-histories`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setCodeList(response.data.code_list.value);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newMapCodeList = [];
        _.map(codeList, (code, key) => {
            let textDisplay = code[i18n.language] ? code[i18n.language] : code.en;
            newMapCodeList.push({ text: textDisplay, value: key });
        })
        setMapCodeList(newMapCodeList);
        // eslint-disable-next-line
    }, [codeList, i18n.language]);
    
    const columns = useMemo(() => ([
        {
            accessorKey: 'id',
            header: `${t('general.id')}`,
        },
        {
            accessorKey: 'trade_redeemable.user.username',
            enableSorting: false,
            header: `${t('trades.user_id')}`,
            Cell: ({ cell }) => (
                <>
                    {
                        cell.row?.original?.trade_redeemable?.user?.username
                            ?
                            cell.row?.original?.trade_redeemable?.user?.username
                            :
                            '-'
                    }
                </>
            )
        },
        {
            header: `${t(`trades.value_code`)}`,
            accessorKey: 'code',
            Cell: ({ cell }) => (cell?.row?.original?.transaction_code_display && (cell?.row?.original?.transaction_code_display[i18n.language] ? cell?.row?.original?.transaction_code_display[i18n.language] : cell?.row?.original?.transaction_code_display?.en)),
            filterFn: 'equals',
            filterSelectOptions: mapCodeList,
            filterVariant: 'select',
            exportKey: `transaction_code_display.${i18n.language}`
        },
        {
            header: `${t(`wallets.addDeduct`)}`,
            id: 'factor',
            accessorFn: (row) => (row.factor === 1 ? t('wallets.add') : t('wallets.deduct')),
            filterFn: 'equals',
            filterSelectOptions: factorList,
            filterVariant: 'select',
        },
        {
            accessorKey: 'redeemable_value',
            header: `${t('tradeRedeemable.redeemableValue')}`,
        },
        {
            accessorKey: 'trade_price',
            header: `${t('trades.price')}`,
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, factorList, codeList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.reportRedeemableTransaction`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.reportRedeemableTransaction`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'TradeRedeemableTransaction'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default Listing
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams , useNavigate} from 'react-router-dom';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

// MUI
import { TextField, Typography, InputAdornment, Box, Card, CardContent, Button, Link } from '@mui/material';

import { MdPerson, MdLock } from "react-icons/md";
import { FiArrowLeft } from "react-icons/fi";
import { MdGroup } from "react-icons/md";

import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';

export default function ResetPassword() {
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const [state, setState] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        token: searchParams.get("token"),
        username: ''
    });

    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const resetPassword = () => {
        setLoading(true);
        postUrl('/reset-password', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                navigate('/login');

            } else {
                setInputErrors(response.errors);
                let msg = response.message;
                _.map(response.errors, (value, index) => {
                    msg += ` ${value}`;
                })
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Box style={{ height: '100%', }}>
            <Card style={{ boxShadow: 'none', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90vh', padding: 20 }}>
                <CardContent className={styles.outerBox}>
                    <Link underline='none' href="/login">
                        <div className={styles.flexMiddle}>
                            <FiArrowLeft style={{ color: theme.palette.secondary.main, fontSize: 16, marginRight: 10 }} />
                            <Typography style={{ color: theme.palette.secondary.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t('button.login')}</Typography>
                        </div>
                    </Link>

                    <div style={{ paddingTop: 25 }}>
                        {/* <center><img src="images/logo2.png" style={{height:100}} alt="logo"/></center> */}
                    </div>

                    <Typography style={{ fontSize: 21, padding: '14px 0px 5px', fontWeight: 'bold', textTransform: 'uppercase', color: theme.palette.gray.ltext }}>
                        {t('resetPassword.title')}
                    </Typography>
                    <Typography style={{ color: theme.palette.gray.main }}>
                        {t('resetPassword.desc')}
                    </Typography>
                    <TextField
                        variant="outlined"
                        label={t('user.email')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdPerson />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        type="email"
                        autoComplete="email"
                        color='primary'
                        value={state.email}
                        onChange={({ target }) => setState({ ...state, email: target.value })}
                        className={styles.marginTop30}
                        helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                        error={inputErrors && inputErrors.email ? true : false}
                    />
                    <TextField
                        variant="outlined"
                        label={t('user.username')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdPerson />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        type="username"
                        autoComplete="username"
                        color='primary'
                        value={state.username}
                        onChange={({ target }) => setState({ ...state, username: target.value })}
                        className={styles.marginTop30}
                        helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                        error={inputErrors && inputErrors.username ? true : false}
                    />
                    <TextField
                        variant="outlined"
                        label={t('user.password')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdLock />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        type="password"
                        autoComplete="password"
                        color='primary'
                        value={state.password}
                        onChange={({ target }) => setState({ ...state, password: target.value })}
                        className={styles.marginTop30}
                        helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                        error={inputErrors && inputErrors.password ? true : false}
                    />
                    <TextField
                        variant="outlined"
                        label={t('user.confirmPassword')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdLock />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        type="password"
                        autoComplete="password"
                        color='primary'
                        value={state.password_confirmation}
                        onChange={({ target }) => setState({ ...state, password_confirmation: target.value })}
                        className={styles.marginTop30}
                        helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                        error={inputErrors && inputErrors.password_confirmation ? true : false}
                    />
                    <Box className={styles.marginTop30} display="flex" justifyContent="center">
                        <Button className={styles.buttonStyle} variant="contained" color="secondary" size="large" onClick={resetPassword} >{t('button.reset')}</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    marginTop30: {
        marginTop: '30px !important'
    },
    outerBox: {
        background: '#fff',
        width: 600,
        padding: '35px 20px',
        maxWidth: '100%',
        borderRadius: 15,
        // height: '100%',
        boxShadow: 'rgb(244 244 244) -10px -10px 16px 0px, rgb(215 215 215) 10px 10px 16px 0px, rgb(244 244 244) 2px 2px 4px 0px inset, rgb(215 215 215) -2px -2px 4px 0px inset',
    },
    buttonStyle: {
        width: '100%',
        boxShadow: '3px 3px 8px 0 #cbc2b7, -3px -3px 4px 0 #fefefe, inset 0 -3px 4px 0 #b99877, inset 0 5px 6px 0 #e3c7ab',
        color: '#fff',
        borderRadius: 9,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    }
}));
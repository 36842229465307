import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { EditNote } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

const MODULE = 'tradeProfile';

const MerchantTradeProfile = () => {
    const theme = useTheme();
    
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }

        getUrl(`/trade-merchant-profile`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const columns = useMemo(() => ([
        {
            header: `${t(`merchant.code`)}`,
            id: 'merchant.code',
            accessorFn: (row) => (row.merchant?.code ? row.merchant?.code : '-'),
            enableSorting: false,
            Cell: ({ cell }) => (
                _.includes(permissions, `merchant-edit`)?
                <Link underline='none' to={`/merchant-edit/${cell.row.original?.merchant?.id}`} component={RouterLink}>
                    { cell.row?.original?.merchant?.code ? cell.row?.original?.merchant?.code : '-' }
                </Link>:
                <></>
            )
        },
        {
            // use for export, hide for display
            header: `${t(`merchant.name`)}`,
            accessorKey: 'merchant.name.' + i18n.language,
        },
        {
            // use for display, hide for export
            accessorKey: 'name',
            header: `${t('merchant.name')}`,
            Cell: ({ cell }) => (
                <>{cell.row.original.merchant.name[i18n.language] ? cell.row.original.merchant.name[i18n.language] : cell.row.original.merchant.name.cn}</>
            ),
            hideExport: true,
            enableSorting: false,
        },
        {
            header: `${t(`user.username`)}`,
            id: 'user.username',
            accessorFn: (row) => (row.merchant?.user?.username ? row.merchant?.user?.username : '-'),
            enableSorting: false,
            Cell: ({ cell }) => (
                _.includes(permissions, `member-edit`)?
                <Link underline='none' to={`/member-edit/${cell.row.original?.merchant?.user?.id}`} component={RouterLink}>
                    { cell.row?.original?.merchant?.user?.username ? cell.row?.original?.merchant?.user?.username : '-' }
                </Link>:
                <></>
            )
        },
        {
            header: `${t(`${MODULE}.passiveUnit`)}`,
            accessorKey: 'passive_unit',
        },
        {
            header: `${t(`tradeRedeemable.withdrawableValue`)}`,
            accessorKey: 'withdrawable_value',
        },
        {
            header: `${t(`tradeRedeemable.redeemableValue`)}`,
            accessorKey: 'redeemable_value',
        },
        {
            header: `${t(`general.updatedAt`)}`,
            accessorKey: 'updated_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.tradeMerchantProfile`)}</b></Typography>
                </Box>
              
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.tradeMerchantProfile`)}</Typography>
                </Breadcrumbs>
            </Grid>
            {
                _.includes(permissions, `trade-merchant-profile-adjust`) 
                ?
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ paddingBottom: 5 }}>
                        <Box display="flex" alignItems="center" >
                            <Link underline='none' to={`/trade-merchant-profile-adjustment`} component={RouterLink}>
                                <IconButton style={{ color: theme.palette.button.main, border: `2px solid ${theme.palette.button.main}`, borderRadius: 5, padding: 0, marginRight: 5 }}>
                                    <EditNote style={{ fontSize: 20 }} />
                                </IconButton>
                            </Link>
                            <Typography>{t(`title.tradeMerchantProfileAdjustment`)}</Typography>
                        </Box>
                    </Grid>
                : null
            }

            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'TradeMerchantProfileList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['merchant.name.' + i18n.language]: false }}
            />
        </>
    )
}

export default MerchantTradeProfile; 
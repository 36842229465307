import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams, useNavigate } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Chip, FormControl, FormHelperText, Grid, Divider, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Typography, Checkbox, ListItemText, ListItemIcon } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import { buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';
import OutlinedInput from '@mui/material/OutlinedInput';

const INITIAL_STATE = {
    name: { en: "", cn: "" },
    bv: '',
    price: '',
    profitLimit: '',
    image: '',
    description: { en: { title: "", subtitle: "" }, cn: { title: "", subtitle: "" } },
    service_fee: { type: "", amount: 0 },
    rank_id: '',
    mrank_id: '',
    status: 1,
    total_distributions: '',
    percent: '',
    split_times: '',
    token_point: '0.00',
    wallet_id: '',
    merchant_id: [],
}

export default function Detail() {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [fieldLang, setFieldLang] = useState({ name: 'en', title: 'en', subtitle: 'en' });
    const [changeImg, setChangeImg] = useState(false);
    const [rankList, setRankList] = useState({});
    const [mrankList, setMRankList] = useState({});
    const [merchantList, setMerchantList] = useState({});

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    let { id } = useParams();
    const location = useLocation();
    const formType = location.pathname === `/plan-add` ? "add" : "edit";

    useEffect(() => {
        setLoading(true);
        if(id){
            getUrl(`/plans/${id}`).then(response => {
                const { data } = response;
                if (response.status) {
                    setState({
                        name: data.name,
                        bv: data?.bv,
                        price: data?.price,
                        profitLimit: data?.profit_limit,
                        image: data?.plan_logo,
                        description: data?.description,
                        service_fee: data?.service_fee,
                        rank_id: data?.rank_id ? data?.rank_id : '',
                        mrank_id: data?.mrank_id ? data?.mrank_id : '',
                        status: data?.status,
                        total_distributions: data?.roi?.total_distributions,
                        percent: data?.roi?.percent,
                        split_times: data?.extra_setting?.split_times,
                        merchant_id: data?.extra_setting?.merchant_id?.[[0]],
                    });
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                // setLoading(false);
            });
        }
        // else{
        //     setState({
        //         name: { en: "", cn: "" },
        //         bv: '',
        //         price: '',
        //         profitLimit: '',
        //         image: '',
        //         description: { en: { title: "", subtitle: "" }, cn: { title: "", subtitle: "" } },
        //         service_fee: { type: "", amount: 0 },
        //         rank_id: 0,
        //         status: 1,
        //     });
        // }

        getUrl(`/active-ranks`).then(response => {
            const { data } = response;
            if (response.status) {
                setRankList(response.data);
                setMRankList(response.mrank);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        getUrl(`/admin/active-merchants`).then(response => {
            const { data } = response;
            if (response.status) {
                setMerchantList(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value, files } = target;
        if (name === 'image') {
            setState({ ...state, image: files[0] });
            setChangeImg(true);
        } else if (name === 'type' || name === 'amount') {
            setState({ ...state, service_fee: { ...state.service_fee, [name]: target.value } });
        } else if (name === 'merchant_id') {
            setState({ ...state, [name]: value });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors([]);
        let postData = {
            ...state,
            profit_limit: state.profitLimit,
            image: formType === 'edit' ? (changeImg ? state.image : '') : state?.image,
            _method: formType === 'edit' ? 'put' : 'post',
        };
        const formData = new FormData();
        buildFormData(formData, postData);
        if (formType === 'edit' && id) {
            postUrl(`/plans/${id}`, formData).then(response => {
                // setLoading(false);
                const { status, errors } = response;
                if (status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                    setChangeImg(false);
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                // setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        } else {
            postUrl(`/plans`, formData).then(response => {
                // setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/plan-edit/${data.id}`, { replace: true });
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                // setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }
    }
    
    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.plan${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div style={{ paddingBottom: 15 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/plans`}>
                            {t(`title.plan`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.plan${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '2%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('plans.name')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, name: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.name === value ? theme.palette.primary.main : '#efefef', color: fieldLang.name === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, name: { ...state.name, [fieldLang.name]: target.value } })}
                            value={state.name && state.name[fieldLang.name] ? state.name[fieldLang.name] : ""}
                            helperText={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn'])}
                            error={inputErrors && (inputErrors['name'] || inputErrors['name.en'] || inputErrors['name.cn']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.bv`)}
                            variant="outlined"
                            value={state.bv}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bv ? inputErrors.bv : ''}
                            error={inputErrors && inputErrors.bv ? true : false}
                            name="bv"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.price`)}
                            variant="outlined"
                            value={state.price}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.price ? inputErrors.price : ''}
                            error={inputErrors && inputErrors.price ? true : false}
                            name="price"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.profitLimit`)}
                            variant="outlined"
                            value={state.profitLimit}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_limit ? inputErrors.profit_limit : ''}
                            error={inputErrors && inputErrors.profit_limit ? true : false}
                            name="profitLimit"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.percent`)}
                            variant="outlined"
                            value={state.percent}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.percent ? inputErrors.percent : ''}
                            error={inputErrors && inputErrors.percent ? true : false}
                            name="percent"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.total_distributions`)}
                            variant="outlined"
                            value={state.total_distributions}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.total_distributions ? inputErrors.total_distributions : ''}
                            error={inputErrors && inputErrors.total_distributions ? true : false}
                            name="total_distributions"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`plans.status`)}</InputLabel>
                            <Select
                                value={state.status}
                                label={t(`plans.status`)}
                                name="status"
                                onChange={({ target }) => setState({ ...state, status: target.value })}
                            >
                                {_.map([1, 0], statusType => {
                                    return <MenuItem key={statusType} value={statusType}>{t(`plans.status_${statusType}`)}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.split_times`)}
                            variant="outlined"
                            value={state.split_times}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.split_times ? inputErrors.split_times : ''}
                            error={inputErrors && inputErrors.split_times ? true : false}
                            name="split_times"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} style={{ padding: '2% 0' }}>
                <Grid item xs={12}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography style={{ color: theme.palette.gray.main }}><b>{t('plans.imagePreview')}</b></Typography>
                        {
                            state?.image &&
                            <img src={_.size(state?.image?.file_name) > 0 ? state?.image?.file_name : URL.createObjectURL(state.image)} style={{ width: 121, height: 121 }} />
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.image`)}
                            variant="outlined"
                            // value={state.image}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.image ? inputErrors.image : ''}
                            error={inputErrors && inputErrors.image ? true : false}
                            name="image"
                            type="file"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Divider />

            <Grid container spacing={3} style={{ padding: '2% 0' }}>
                <Grid item xs={12}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography style={{ color: theme.palette.gray.main }}><b>{t('plans.description')} & {t('plans.otherSettings')}</b></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('plans.title')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, description: { ...state.description, [fieldLang?.title]: { ...state.description?.[fieldLang.title], title: target.value } } })}
                            value={state.description && state.description?.[fieldLang.title]?.title ? state.description?.[fieldLang.title]?.title : ""}
                            helperText={inputErrors && (inputErrors['description'] || inputErrors['description.en.title'] || inputErrors['description.cn.title'])}
                            error={inputErrors && (inputErrors['description'] || inputErrors['description.en.title'] || inputErrors['description.cn.title']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('plans.subtitle')}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    {
                                        _.map(['en', 'cn'], (value) => (
                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, subtitle: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.subtitle === value ? theme.palette.primary.main : '#efefef', color: fieldLang.subtitle === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                        ))
                                    }
                                </InputAdornment>,
                            }}
                            onChange={({ target }) => setState({ ...state, description: { ...state.description, [fieldLang.subtitle]: { ...state.description?.[fieldLang.subtitle], subtitle: target.value } } })}
                            value={state.description && state.description?.[fieldLang.subtitle]?.subtitle ? state.description?.[fieldLang.subtitle]?.subtitle : ""}
                            helperText={inputErrors && (inputErrors['description'] || inputErrors['description.en.subtitle'] || inputErrors['description.cn.subtitle'])}
                            error={inputErrors && (inputErrors['description'] || inputErrors['description.en.subtitle'] || inputErrors['description.cn.subtitle']) ? true : false}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`plans.type`)}</InputLabel>
                            <Select
                                value={state.service_fee?.type || ""}
                                label={t(`plans.type`)}
                                name="type"
                                onChange={handleChange}
                                error={inputErrors && inputErrors?.['service_fee.type'] ? true : false}
                            >
                                <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                {_.map([
                                    { 'code': 'fixed', 'label': t(`plans.typeFixed`) },
                                    { 'code': 'percent', 'label': t(`plans.typePercent`) }
                                ], serviceFeeType => {
                                    return <MenuItem key={serviceFeeType.code} value={serviceFeeType.code}>{serviceFeeType.label}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors?.['service_fee.type'] ? inputErrors?.['service_fee.type'] : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.amount`)}
                            variant="outlined"
                            value={state?.service_fee?.amount}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors?.['service_fee.amount'] ? inputErrors?.['service_fee.amount'] : ''}
                            error={inputErrors && inputErrors?.['service_fee.amount'] ? true : false}
                            name="amount"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{ state?.service_fee?.type === 'Percent' ? '%' : '' }</InputAdornment>,
                            }}
                            onChange={handleChange}
                        />
                        {
                            state?.service_fee?.type === 'percent' &&
                            <Typography variant="caption">{t('plans.calculateAmount', {value: (state.price*(state.service_fee?.amount/100) || 0).toFixed(2)})}</Typography>
                        }
                    </Box>
                </Grid>
                {
                    _.size(rankList) > 0
                        ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.rankId`)}</InputLabel>
                                    <Select
                                        value={state.rank_id}
                                        label={t(`plans.rankId`)}
                                        name="rank_id"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map(rankList, rank => {
                                            return <MenuItem key={rank.id} value={rank.id}>{rank?.name ? rank?.name?.[i18n.language] : rank?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                }
                {
                    _.size(mrankList) > 0
                        ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.mrankId`)}</InputLabel>
                                    <Select
                                        value={state.mrank_id}
                                        label={t(`plans.mrankId`)}
                                        name="mrank_id"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map(mrankList, rank => {
                                            return <MenuItem key={rank.id} value={rank.id}>{rank?.name ? rank?.name?.[i18n.language] : rank?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                }
                {
                    // _.size(merchantList) > 0
                    //     ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`plans.merchant`)}</InputLabel>
                                    <Select
                                        value={state?.merchant_id}
                                        label={t(`plans.merchant`)}
                                        name="merchant_id"
                                        onChange={handleChange}
                                        error={inputErrors && inputErrors?.merchant_id ? true : false}
                                    >
                                        <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem>
                                        {_.map(merchantList, merchant => {
                                            return (
                                                <MenuItem key={merchant.id} value={merchant.id}>
                                                    {merchant?.name ? merchant?.name?.[i18n.language] : merchant?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}
                                                    &nbsp;({merchant?.code})
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors?.merchant_id ? inputErrors?.merchant_id : ''}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        // :
                        // null
                }
            </Grid>

            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/plans`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))
import React from 'react';
import { Dialog, DialogActions,DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BsX } from "react-icons/bs";

const ImageShowModal = ({ open, closeDialog, info}) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm" >
            <DialogActions>
                <BsX onClick={closeDialog} style={{ cursor: 'pointer' }} scroll="true" size={30} />
            </DialogActions>
            <DialogContent>
                <img
                    src={info.image}
                    srcSet={info.image}
                    alt={`gallery_${info.id}`}
                    loading="lazy"
                    style={{ height: "100%", width: "100%" }}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ImageShowModal;
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography, TextField, Radio, RadioGroup, FormControl, FormControlLabel, InputAdornment, Button } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';
import { Close as CloseIcon } from '@mui/icons-material';

import SearchComponent from '@components/SearchComponent';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [bonusList, setBonusList] = useState([]);
    const [bonusListForSelect, setBonusListForSelect] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    const [selectedGenealogyType, setSelectedGenealogyType] = useState("individual");
    const [searchValue, setSearchValue] = useState('');

    const handleGenealogyTypeChange = (event) => {
        setSelectedGenealogyType(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchValue('');
        setSelectedGenealogyType("individual");
        callApi()
        // handleSearchAndApiCall();
    };

    const handleSearchAndApiCall = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
            search: searchValue ? searchValue : '',
            genealogyType: searchValue ? selectedGenealogyType : ''
        };
        getUrl(`/commissions`, apiData)
            .then(response => {
                setTableLoading(false);
                if (response.status) {
                    setData(response.data.listing.data);
                    setRowCount(response.data.listing.total);
                    setModuleStatusList(response.data.status_list);
                    setBonusList(response.data.bonus_list);
                } else {
                    addAlert("", response.error, 'error', '');
                }
            })
            .catch(error => {
                setTableLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
    };
    
    const handleSearch = () => {
        handleSearchAndApiCall();
    };
    
    useEffect(() => {
        handleSearchAndApiCall();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);    

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/commissions`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
                setBonusList(response.data.bonus_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // useEffect(() => {
    //     setTableLoading(true);
    //     let apiData = {
    //         page: pagination.pageIndex + 1,
    //         per_page: pagination.pageSize,
    //         filters: columnFilters,
    //         sorting: sorting[0],
    //     }
    //     getUrl(`/commissions`, apiData).then(response => {
    //         setTableLoading(false);
    //         if (response.status) {
    //             setData(response.data.listing.data);
    //             setRowCount(response.data.listing.total);
    //             setModuleStatusList(response.data.status_list);
    //             setBonusList(response.data.bonus_list);
    //         } else {
    //             addAlert("", t('error.contactSupport'), 'error', '');
    //         }
    //     }).catch(error => {
    //         setTableLoading(false);
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     });
    //     // eslint-disable-next-line
    // }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    useEffect(() => {
        let newList = [];
        _.map(bonusList, (data, key) => {
            let textDisplay = data.name[i18n.language] ? data.name[i18n.language] : data.name.en;
            newList.push({ text: textDisplay, value: data.id.toString() });
        })
        setBonusListForSelect(newList);
        // eslint-disable-next-line
    }, [bonusList, i18n.language]);   
    
    const columns = useMemo(() => ([
        {
            accessorKey: 'bonus_date',
            header: `${t('commission.bonusDate')}`,
        },
        {
            accessorKey: 'bonus_id',
            header: `${t('commission.bonusName')}`,
            filterFn: 'equals',
            filterSelectOptions: bonusListForSelect,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{cell.row.original.bonus?.name[i18n.language] ? cell.row.original.bonus?.name[i18n.language] : cell.row.original.bonus?.name.en}</>
            ),
            exportKey: `bonus.name.${i18n.language}`
        },
        {
            accessorKey: 'user.username',
            header: `${t('commission.username')}`,
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => (row.downline_username ? row.downline_username : '-'),
            id: 'downline_username',
            header: `${t('commission.downline')}`,
        },
        {
            accessorFn: (row) => (row.bv ? row.bv : '-'),
            id: 'bv',
            header: `${t('commission.bv')}`,
        },
        {
            accessorKey: 'percent',
            header: `${t('commission.percent')}`,
        },
        {
            accessorFn: (row) => (row.amount ? row.amount : '-'),
            id: 'amount',
            header: `${t('commission.amount')}`,
        },
        {
            accessorFn: (row) => (row.pairing_flushed ? row.pairing_flushed : '-'),
            id: 'pairing_flushed',
            header: `${t('commission.pairingFlushed')}`,
        },
        {
            accessorFn: (row) => (row.flushed ? row.flushed : '-'),
            id: 'flushed',
            header: `${t('commission.flushed')}`,
        },
        {
            accessorKey: 'status',
            header: `${t('commission.status')}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{cell.row.original?.status_display[i18n.language] ? cell.row.original?.status_display[i18n.language] : cell.row.original?.status_display.en}</>
            ),
            exportKey: `status_display.${i18n.language}`
        },
        {
            accessorFn: (row) => (row.level ? row.level : '-'),
            id: 'level',
            header: `${t('commission.level')}`,
        },
        {
            accessorFn: (row) => (row.blevel ? row.blevel : '-'),
            id: 'blevel',
            header: `${t('commission.blevel')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList, bonusListForSelect]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.commission`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.commission`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <SearchComponent
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleClearSearch={handleClearSearch}
                handleSearch={handleSearch}
                selectedGenealogyType={selectedGenealogyType}
                handleGenealogyTypeChange={handleGenealogyTypeChange}
            />
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'CommissionList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default Listing
import React, { useState , useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl, putUrl } from '@utils/ApiAction';

import { Link, TextField, Box, Button,  Grid, Typography, FormControl , InputLabel , Select , MenuItem , FormHelperText } from '@mui/material';
import BackToPrevious from '@layouts/BackToPrevious';

const MODULE = 'merchant';
const INITIAL_STATE = {
    withdrawFunction: '',
    useParam: '',
    feeType: '',
    withdrawMethod: '',
    serviceFeeNumber: '',
    serviceFeeMethod: '',
    withdrawPercent: '',
    code: '',
    type: '',
}

const MerchantStrategiesWithdrawalSetting = () => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const [state, setState] = useState(INITIAL_STATE);

    useEffect (() => {
        callApi();
        
        // eslint-disable-next-linex
    }, []);
    
    const callApi = () => {
        setLoading(true);
        getUrl('/admin-get-trade-strategies-withdrawals-setting' ).then(response => {
            if (response.status)
            {
                setState({
                    ...state,
                    serviceFeeNumber: response?.data?.value?.service_fee_number,
                    withdrawPercent: response?.data?.value?.withdraw_percent,

                    withdrawFunction: response?.data?.value?.withdraw_function,
                    useParam: response?.data?.value?.use_param,
                    feeType: response?.data?.value?.fee_type,
                    withdrawMethod: response?.data?.value?.withdraw_method,
                    serviceFeeMethod: response?.data?.value?.service_fee_method,

                    code: response?.data?.code,
                    type: response?.data?.type,
                });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })
        ;
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile' || name === 'split_price') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }else if (name === 'username') {
            value = value.replace(/[^\w\s]/gi, "").toLowerCase();
        }
        setState({ ...state, [name]: value });
    };

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            withdraw_function: state.withdrawFunction,
            use_param: state.useParam,
            fee_type: state.feeType,
            withdraw_method: state.withdrawMethod,
            service_fee_number: state.serviceFeeNumber,
            service_fee_method: state.serviceFeeMethod,
            withdraw_percent: state.withdrawPercent,
            code: state.code,
            type: state.type,
        }

        putUrl(`/update-strategies-withdrawal-setting`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({
                    ...state,
                    serviceFeeNumber: response?.data?.value?.service_fee_number,
                    withdrawPercent: response?.data?.value?.withdraw_percent,

                    withdrawFunction: response?.data?.value?.withdraw_function,
                    useParam: response?.data?.value?.use_param,
                    feeType: response?.data?.value?.fee_type,
                    withdrawMethod: response?.data?.value?.withdraw_method,
                    serviceFeeMethod: response?.data?.value?.service_fee_method,

                    code: response?.data?.code,
                    type: response?.data?.type,
                });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.merchantStrategiesWithdrawalSettingTitle`)}</b></Typography>
            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
            
                {/* code */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.code`)}
                            variant="outlined"
                            value={state?.code ?? ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            onChange={handleChange}
                            disabled
                        />
                    </Box>
                </Grid>

                {/* type */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.type`)}
                            variant="outlined"
                            value={state?.type ?? ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.type ? inputErrors.type : ''}
                            error={inputErrors && inputErrors.type ? true : false}
                            name="type"
                            onChange={handleChange}
                            disabled
                        />
                    </Box>
                </Grid>

            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>

                {/* withdrawFunction */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth error={inputErrors && inputErrors.withdraw_function ? true : false}>
                            <InputLabel>{t(`${MODULE}.withdrawFunction`)}</InputLabel>
                            <Select
                                value={ state?.withdrawFunction ?? 'null' }
                                label={t(`${MODULE}.withdrawFunction`)}
                                name="withdrawFunction"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} value={'null'} disabled >--- {`${t('general.pleaseSelect')} `} --- </MenuItem>
                                <MenuItem key={1} value={true}>{t(`${MODULE}.on`)}</MenuItem>
                                <MenuItem key={2} value={false}>{t(`${MODULE}.off`)}</MenuItem>
                                
                            </Select>
                            {
                                inputErrors && inputErrors?.withdraw_function &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.withdraw_function}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>

                {/* withdrawMethod */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth error={inputErrors && inputErrors.withdraw_method ? true : false}>
                            <InputLabel>{t(`${MODULE}.withdrawMethod`)}</InputLabel>
                            <Select
                                value={ state?.withdrawMethod ?? '0' }
                                label={t(`${MODULE}.withdrawMethod`)}
                                name="withdrawMethod"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} value={'0'} disabled >--- {`${t('general.pleaseSelect')} `} --- </MenuItem>
                                <MenuItem key={1} value={'bicpay'}>{t(`${MODULE}.bicpay`)}</MenuItem>
                                {/* <MenuItem key={2} value={'local'}>{t(`${MODULE}.local`)}</MenuItem> */}
                            </Select>
                            {
                                inputErrors && inputErrors?.withdraw_method &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.withdraw_method}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>

                {/* useParam */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth error={inputErrors && inputErrors.use_param ? true : false}>
                            <InputLabel>{t(`${MODULE}.useParam`)}</InputLabel>
                            <Select
                                value={ state?.useParam ?? '0' }
                                label={t(`${MODULE}.useParam`)}
                                name="useParam"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} value={'0'}disabled >--- {`${t('general.pleaseSelect')} `} --- </MenuItem>
                                <MenuItem key={1} value={'merchant'}>{t(`${MODULE}.merchant`)}</MenuItem>
                                <MenuItem key={2} value={'system'}>{t(`${MODULE}.system`)}</MenuItem>
                                
                            </Select>
                            {
                                inputErrors && inputErrors?.use_param &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.use_param}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>

                {/* feeType */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth error={inputErrors && inputErrors.fee_type ? true : false}>
                            <InputLabel>{t(`${MODULE}.feeType`)}</InputLabel>
                            <Select
                                value={ state?.feeType ?? '0' }
                                label={t(`${MODULE}.feeType`)}
                                name="feeType"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} value={'0'} disabled >--- {`${t('general.pleaseSelect')} `} --- </MenuItem>
                                {/* <MenuItem key={1} value={'addition'}>{t(`${MODULE}.addition`)}</MenuItem> */}
                                <MenuItem key={2} value={'reduction'}>{t(`${MODULE}.reduction`)}</MenuItem>
                                
                            </Select>
                            {
                                inputErrors && inputErrors?.fee_type &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.fee_type}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>

                {/* serviceFeeMethod */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth error={inputErrors && inputErrors.service_fee_method ? true : false}>
                            <InputLabel>{t(`${MODULE}.serviceFeeMethod`)}</InputLabel>
                            <Select
                                value={ state?.serviceFeeMethod ?? '0' }
                                label={t(`${MODULE}.serviceFeeMethod`)}
                                name="serviceFeeMethod"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} value={'0'} disabled >--- {`${t('general.pleaseSelect')} `} --- </MenuItem>
                                <MenuItem key={1} value={'percent'}>{t(`${MODULE}.percent`)}</MenuItem>
                                <MenuItem key={2} value={'fixed'}>{t(`${MODULE}.fixed`)}</MenuItem>
                            </Select>
                            {
                                inputErrors && inputErrors?.service_fee_method &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.service_fee_method}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>

                {/* serviceFeeNumber */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.serviceFeeNumber`)}
                            variant="outlined"
                            value={ state?.serviceFeeNumber ?? '' }
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.service_fee_number ? inputErrors.service_fee_number : ''}
                            error={inputErrors && inputErrors.service_fee_number ? true : false}
                            name="serviceFeeNumber"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

                {/* withdrawPercent */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.withdrawPercent`)}
                            variant="outlined"
                            value={ state?.withdrawPercent ?? '' }
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.withdraw_percent ? inputErrors.withdraw_percent : ''}
                            error={inputErrors && inputErrors.withdraw_percent ? true : false}
                            name="withdrawPercent"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

            </Grid>

            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/members`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.save')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default MerchantStrategiesWithdrawalSetting;
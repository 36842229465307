import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Card, CardContent, IconButton, InputAdornment, TextField ,Grid , Link} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdLock, MdPerson, MdVisibility, MdVisibilityOff } from "react-icons/md";

import { authFail, authSuccess } from '@actions';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function Login() {
    const { t } = useTranslation();
    const styles = useStyles();
    const [state, setState] = useState({
        username: '',
        password: '',
        showPassword: false,
        type: 'web',
        value: "0"
    });
    const [inputErrors, setInputErrors] = useState();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/dashboard";

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const loginUser = (event) => {
        setLoading(true);
        event.preventDefault();
        postUrl('/login', state).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(authSuccess(response.data));
                navigate(from, { replace: true });
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Box style={{ height: '100%', }} >
            <Card style={{ boxShadow: 'none', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90vh', padding: 20, backgroundImage: `url('/images/login_image.png')` }} component="form" onSubmit={loginUser}>
                <CardContent className={styles.outerBox}>
                    <center><img src="../logo512.png" style={{ height: 100, margin: '10px 0 20px' }} alt="logo" /></center>
                    <TextField
                        variant="outlined"
                        label={t('user.username')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdPerson />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        autoComplete="username"
                        color='primary'
                        value={state.username}
                        onChange={({ target }) => setState({ ...state, username: target.value })}
                        className={styles.marginTop30}
                        helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                        error={inputErrors && inputErrors.username ? true : false}
                    />
                    <TextField
                        variant="outlined"
                        label={t('user.password')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdLock />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {state.showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        type={state.showPassword ? 'text' : 'password'}
                        color='primary'
                        value={state.password}
                        onChange={({ target }) => setState({ ...state, password: target.value })}
                        className={styles.marginTop30}
                        helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                        error={inputErrors && inputErrors.password ? true : false}
                    />
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={styles.marginTop30}>
                        <Link href="/forget-password" color="secondary" underline="none">{t('login.forgetPassword')}</Link>
                    </Grid>
                    <Box style={{ padding: '10px 0 10px' }} display="flex" justifyContent="center">
                        <Button style={{ width: '80%' }} className={styles.buttonStyle} variant="contained" color="primary" size="large" type="submit" >{t('button.login')}</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    marginTop30: {
        marginBottom: '30px !important',
        margin: 10
    },
    outerBox: {
        background: '#ffffff',
        width: 600,
        padding: '35px 20px',
        maxWidth: '100%',
        borderRadius: 15,
    },
    buttonStyle: {
        boxShadow: '3px 3px 8px 0 #b7c4cb, -3px -3px 4px 0 #fefefe, inset 0 -3px 4px 0 #b99877, inset 0 5px 6px 0 #e3c7ab',
        color: '#fff',
        borderRadius: 9,
    },
}));
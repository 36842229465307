import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeLanguage as reduxChangeLanguage, storeProfile } from '@actions';
import { getUrl, removeLoginAccess } from '@utils/ApiAction';
import { truncate } from '@utils/Tools';
import _ from 'lodash';

import { styled, useTheme } from '@mui/material/styles';
import { Box, Toolbar, List, Typography, Divider, IconButton, ListItemButton, ListItemIcon, ListItemText, Collapse, Link, Menu, MenuItem, Avatar } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

// Icon
import { MdLogout, MdGTranslate, MdMoreVert, MdExpandLess, MdExpandMore, MdChevronRight, MdChevronLeft, MdAccountBox } from "react-icons/md";
import { BsShop } from "react-icons/bs";

import useNotificationLoading from '@utils/useNotificationLoading';


const drawerWidth = 240;
const DEFAULT_STATE = {
    languageAnchorEl: null,
    mobileAnchorEl: null,
};

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer({ routeProps, content }) {
    const theme = useTheme();
    const [currentPath, setCurrentPath] = useState();
    const [showDrawer, setShowDrawer] = useState(false);
    const [state, setState] = useState(DEFAULT_STATE);
    const [levelOpen, setLevelOpen] = useState({});
    const { accessToken, permissions, allNetwork } = useSelector(state => state.general);
    const { name, profile_picture, network, merchant_id } = useSelector(state => state.user);
    const { addAlert } = useNotificationLoading();

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let location = useLocation();
    let page = location.pathname;
    let navigate = useNavigate();

    useEffect(() => {
        let active = true;
        if (active) {
            const path = _.split(location.pathname, '/');
            if (path) {
                setCurrentPath(path[1].toLowerCase());
            }
        }
        return () => { active = false };
    }, [location.pathname]);

    useEffect(() => {
        setShowDrawer(false);
        setLevelOpen({});
    }, [currentPath])

    // selected page in category expand it
    useEffect(() => {
        let categoryList = [];
        _.map(routeProps, category => {
            categoryList = categoryList.concat({ [category.name]: false });
            if (category.category) {
                _.map(category.children, route => {
                    if (page === route.path) {
                        handleOpenSecondLevel(category.name);
                    }
                })
            }
        })
        setLevelOpen(categoryList);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (_.includes(['en', 'cn'], i18n.language) === false) {
            changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE || 'en');
        }
        // eslint-disable-next-line
    }, [i18n.language, t]);

    useEffect(() => {
        if (accessToken) {
            getUrl('/user').then(response => {
                if (response.status) {
                    dispatch(storeProfile(response.data));
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                removeLoginAccess();
                navigate('/login');
            });
        }
        // eslint-disable-next-line
    }, [dispatch, addAlert]);

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
        handleMenuClose();
    };

    const handleMenuClose = () => {
        setState(DEFAULT_STATE);
    };

    const handleOpenSecondLevel = type => {
        setShowDrawer(true);
        setLevelOpen({ ...levelOpen, [type]: !levelOpen[type] });
    }

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
        setLevelOpen({});
    };

    const logoutUser = () => {
        // localStorage.removeItem('accessToken');
        removeLoginAccess();
        dispatch(reduxChangeLanguage(i18n.language));
        navigate('/login');
    }

    const sidebarListUrl = () => {
        return (
            <>
                {
                    _.map(routeProps, category => {
                        /* check permission here, have either one permission in categoryPermission */
                        if (category.category) {
                            // LinkGroup
                            if (category.categorySidebar) {
                                if (!_.size(category.categoryPermission) || _.size(_.intersection(permissions, category.categoryPermission)) > 0) {
                                    return (
                                        <div key={category.categoryName}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: showDrawer ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                                onClick={() => handleOpenSecondLevel(category.categoryName)}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: showDrawer ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                        // color: theme.palette.secondary.main,
                                                    }}
                                                >
                                                    {category.categoryIcon}
                                                </ListItemIcon>
                                                <ListItemText primary={t(category.categoryLabel)} sx={{ opacity: showDrawer ? 1 : 0 }} />
                                                {levelOpen[category.categoryName] ? <MdExpandLess /> : <MdExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={levelOpen[category.categoryName]} timeout="auto" >
                                                {
                                                    _.map(category.children, list => {
                                                        return sidebarLinkContent(list, true);
                                                    })
                                                }
                                            </Collapse>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            }
                        } else {
                            // Link
                            return (
                                <div key={category.categoryName}>
                                    {
                                        _.map(category.children, list => {
                                            return sidebarLinkContent(list, false);
                                        })
                                    }
                                </div>
                            )
                        }
                    })
                }
            </>
        )
    }



    const sidebarLinkContent = (content, sub) => {
        let fulfilledAllPermission = true;
        if (content.sidebar /* check permission here */) {
            if (_.size(content.permission) > 0) {
                _.map(content.permission, (contentPermission) => {
                    if (!_.includes(permissions, contentPermission)) {
                        fulfilledAllPermission = false;
                    }
                })
            }
            if (fulfilledAllPermission) {
                const path = content.path;
                const blockchain = path.split('/').pop();
                if (merchant_id !== null && (_.size(network) > 0 && !network.includes(blockchain)) && allNetwork.includes(blockchain)){
                    return null
                }
                return (
                    <Link key={content.path} underline='none' to={content.path} component={RouterLink}>
                        <ListItemButton
                            key={content.path}
                            sx={{
                                minHeight: 48,
                                justifyContent: showDrawer ? 'initial' : 'center',
                                px: 2.5,
                                // borderLeft: page === content.path ? 'solid' : 'none',
                                // borderLeftColor: theme.palette.secondary.main,
                                // borderLeftWidth: 5,
                            }}
                            selected={page === content.path ? true : false}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: showDrawer ? 3 : 'auto',
                                    justifyContent: 'center',
                                    // color: theme.palette.secondary.main
                                }}
                            >
                                {sub ? '' : content.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={showDrawer ? t(content.title) : t(content.title).substring(0, 3)}
                                sx={{ opacity: sub ? 1 : showDrawer ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </Link>
                )
            }
        } else {
            return null;
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {/* Header */}
            <AppBar position="fixed" open={showDrawer} color='inherit' style={{ zIndex: 1200, width: '100%', boxShadow: '0 6px 20px 0 #c4c4c463' }}>
                <Toolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Link underline='none' component={RouterLink} to={`/profile`}>
                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: 15 }} xs={2}>
                                {profile_picture ?
                                    <Avatar sx={{ bgcolor: theme.palette.secondary.main }} src={profile_picture.file_name} />
                                    :
                                    <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>{name ? name.substring(0,2) : '-'}</Avatar>
                                }
                                <Typography paddingLeft={2}>{truncate(name, 15)}</Typography>
                            </div>
                        </Link>
                        <IconButton
                            aria-label="show more"
                            aria-haspopup="true"
                            onClick={(event) => setState({ ...state, mobileAnchorEl: event.currentTarget })}
                            color="inherit"
                        >
                            <MdMoreVert />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>


            {/* Menu Dropdown */}
            <Menu
                anchorEl={state.mobileAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(state.mobileAnchorEl)}
                onClose={handleMenuClose}
            >
                {
                    (merchant_id && _.includes(permissions, "merchantProfile")) &&
                    <MenuItem component={RouterLink} to={'/merchant-edit/' + merchant_id} onClick={handleMenuClose}>
                        <IconButton
                            aria-controls="primary-search-logout-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <BsShop />
                        </IconButton>
                        <p>{t('button.merchantSetting')}</p>
                    </MenuItem>
                }
                <MenuItem component={RouterLink} to='/profile' onClick={handleMenuClose}>
                    <IconButton
                        aria-controls="primary-search-language-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <MdAccountBox />
                    </IconButton>
                    <p>{t('title.profile')}</p>
                </MenuItem>
                <MenuItem onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget })} >
                    <IconButton
                        aria-controls="primary-search-language-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <MdGTranslate />
                    </IconButton>
                    <p>{t('general.language')}</p>
                </MenuItem>
                {
                    accessToken &&
                    <MenuItem onClick={logoutUser} >
                        <IconButton
                            aria-controls="primary-search-logout-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <MdLogout />
                        </IconButton>
                        <p>{t('button.logout')}</p>
                    </MenuItem>
                }
            </Menu>

            {/* Language Dropdown */}
            <Menu
                anchorEl={state.languageAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(state.languageAnchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => changeLanguage('en')}>{t('general.en')}</MenuItem>
                <MenuItem onClick={() => changeLanguage('cn')}>{t('general.cn')}</MenuItem>
            </Menu>

            {/* Sidebar */}
            <Drawer
                variant="permanent"
                open={showDrawer}
            // PaperProps={{
            //     sx: {
            //         backgroundColor: theme.palette.primary.main,
            //         color: theme.palette.secondary.main
            //     }
            // }}
            >
                <DrawerHeader>
                    <IconButton onClick={toggleDrawer} >
                        {/* {theme.direction === 'rtl' ? <MdChevronRight /> : <MdChevronLeft />} */}
                        {showDrawer ? <MdChevronLeft /> : <MdChevronRight />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {sidebarListUrl()}
                </List>
            </Drawer>

            <Box component="main" style={{ width: `calc(100% - ${drawerWidth}px)`, padding: '24px 0' }} sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {content}
            </Box>
        </Box>
    );
}

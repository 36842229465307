import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

import SearchComponent from '@components/SearchComponent';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    const [selectedGenealogyType, setSelectedGenealogyType] = useState("individual");
    const [searchValue, setSearchValue] = useState('');

    const handleGenealogyTypeChange = (event) => {
        setSelectedGenealogyType(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchValue('');
        setSelectedGenealogyType("individual");
        callApi()
        // handleSearchAndApiCall();
    };

    const handleSearchAndApiCall = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
            search: searchValue ? searchValue : '',
            genealogyType: searchValue ? selectedGenealogyType : ''
        }
        getUrl(`/plan-histories?status=all`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", response.error, 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    };
    
    const handleSearch = () => {
        handleSearchAndApiCall();
    };

    useEffect(() => {
        handleSearchAndApiCall();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/plan-histories?status=all`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", response.error, 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // useEffect(() => {
    //     setTableLoading(true);
    //     let apiData = {
    //         page: pagination.pageIndex + 1,
    //         per_page: pagination.pageSize,
    //         filters: columnFilters,
    //         sorting: sorting[0],
    //     }
    //     getUrl(`/plan-histories?status=all`, apiData).then(response => {
    //         setTableLoading(false);
    //         if (response.status) {
    //             setData(response.data.listing.data);
    //             setRowCount(response.data.listing.total);
    //             setModuleStatusList(response.data.status_list);
    //         } else {
    //             addAlert("", t('error.contactSupport'), 'error', '');
    //         }
    //     }).catch(error => {
    //         setTableLoading(false);
    //         addAlert('', error.message || t('error.contactSupport'), 'error', '');
    //     });
    //     // eslint-disable-next-line
    // }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `plan-history-edit`) ?
                    <Link underline='none' to={`/plan-history-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit style={{ color: theme.palette.button.main }} />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50,
            hideExport: true,
        },
        {
            accessorKey: 'id',
            header: `${t('general.id')}`,
        },
        {
            accessorFn: (row) => (row.plan_historable.username ? row.plan_historable.username : '-'),
            id: 'plan_historable.username',
            header: `${t('planHistories.userId')}`,
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            // use for export, hide for display
            header: `${t('plans.name')}`,
            accessorKey: 'plan_name.' + i18n.language,
        },
        {
            // use for display, hide for export
            accessorKey: 'plan_name',
            header: `${t('plans.name')}`,
            Cell: ({ cell }) => (
                <>{cell.row.original.plan_name[i18n.language] ? cell.row.original.plan_name[i18n.language] : cell.row.original.plan_name.en}</>
            ),
            hideExport: true,
        },
        {
            accessorFn: (row) => (row.bv ? row.bv : '-'),
            id: 'bv',
            header: `${t('plans.bv')}`,
        },
        {
            accessorFn: (row) => (row.price ? row.price : '-'),
            id: 'price',
            header: `${t('plans.price')}`,
        },
        {
            accessorFn: (row) => (row.roi ? row.roi.percent : '-'),
            id: 'roi.percent',
            header: `${t('plans.percent')}`,
            enableSorting: false,
        },
        {
            accessorFn: (row) => (row.roi ? row.roi.total_distributions : '-'),
            id: 'roi.total_distributions',
            header: `${t('plans.total_distributions')}`,
            enableSorting: false,
        },
        {
            accessorFn: (row) => (row.roi ? row.roi.received_times : '-'),
            id: 'roi.received_times',
            header: `${t('planHistories.received_times')}`,
            enableSorting: false,
        },
        {
            accessorFn: (row) => (row.profit_limit ? row.profit_limit : '-'),
            id: 'profit_limit',
            header: `${t('plans.profitLimit')}`,
        },
        {
            accessorFn: (row) => (row.profit_limit_balance ? row.profit_limit_balance : '-'),
            id: 'profit_limit_balance',
            header: `${t('plans.profitLimitBalance')}`,
        },
        {
            // use for display, hide for export
            accessorKey: 'plbpl',
            header: `${t('plans.plbpl')}`,
            Cell: ({ cell }) => (
                <>{(cell.row.original.profit_limit_balance ? cell.row.original.profit_limit_balance : 0)+" / "+(cell.row.original.profit_limit ? cell.row.original.profit_limit : 0)}</>
            ),
            hideExport: true,
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'status',
            header: `${t('planHistories.status')}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            Cell: ({ cell }) => {
                let statusDisplay = _.find(statusList, function(s){return parseInt(s.value) === cell.row.original.status;})
                return <>{statusDisplay?.text}</>
            },
        },
        {
            accessorKey: 'creator.username',
            header: `${t('general.creator')}`,
            enableSorting: false,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // {
        //     accessorKey: 'updated_at',
        //     header: `${t('general.updatedAt')}`,
        // },
        {
            header: `${t(`general.ulid`)}`,
            accessorKey: 'ulid',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.planHistory`)}</b></Typography>
                    {_.includes(permissions, `plan-history-create`) ?
                        <Link underline='none' to={`/plan-history-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.planHistory`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <SearchComponent
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleClearSearch={handleClearSearch}
                handleSearch={handleSearch}
                selectedGenealogyType={selectedGenealogyType}
                handleGenealogyTypeChange={handleGenealogyTypeChange}
            />
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'PlanHistoryList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['plan_name.' + i18n.language]: false, 'profit_limit': false, 'profit_limit_balance': false }}
            />
        </>
    )
}

export default Listing
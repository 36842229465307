import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, FormControl, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const INITIAL_Plan_Histories = {
    plan_name: '',
    bv: '',
    price: '',
    profit_limit: '',
    profit_limit_balance: '',
    status: '',
    roi: {
        ROI_day: '',
        percent: '',
        received_times: '',
        total_distributions: '',
    }
}

export default function Edit() {
    const [inputErrors, setInputErrors] = useState({});
    const [planHistories, setPlanHistories] = useState(INITIAL_Plan_Histories);
    const [statusList, setStatusList] = useState({});

    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading, getConfirmation } = useNotificationLoading();

    useEffect(() => {
        getUrl(`/plan-histories/${id}`).then(response => {
            const { data } = response;
            if (response.status) {
                setPlanHistories(data);
                setStatusList(response.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);
    const handleChange = ({ target }) => {
        const { name, value } = target;
        setPlanHistories({ ...planHistories, [name]: value });
    }

    const submitData = async () => {
        const confirmation = await getConfirmation();
        if(confirmation.status){
            setLoading(true);
            setInputErrors();
            const updatedPlanHistories = {
                ...planHistories,
                audit_remark: confirmation.note
            };
            putUrl(`/plan-histories/${id}`, updatedPlanHistories).then(response => {
                setLoading(false);
                const { status, errors } = response;
                if (status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.planHistoryEdit`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/plan-histories`}>
                            {t(`title.planHistory`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.planHistoryEdit`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container style={{marginLeft: 10, marginBottom: 20}}>
                <Grid item xs={3}>
                    <Typography>{t(`general.ulid`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.ulid}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`user.username`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories?.plan_historable?.username}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`plans.name`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.plan_name[i18n.language] || ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`plans.merchant`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.merchant?.name[i18n.language] || ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`general.creator`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories?.creator?.username}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`general.createdAt`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.created_at}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{t(`plans.payment`)}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography>: {planHistories.payment_display}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.bv`)}
                            variant="outlined"
                            value={planHistories.bv || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.bv ? inputErrors.bv : ''}
                            error={inputErrors && inputErrors.bv ? true : false}
                            name="bv"
                            type="number"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: 'lightgrey',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.price`)}
                            variant="outlined"
                            value={planHistories.price || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.price ? inputErrors.price : ''}
                            error={inputErrors && inputErrors.price ? true : false}
                            name="price"
                            type="number"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: 'lightgrey',
                            }}
                        />
                    </Box>
                </Grid>
                
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.profitLimit`)}
                            variant="outlined"
                            value={planHistories.profit_limit || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_limit ? inputErrors.profit_limit : ''}
                            error={inputErrors && inputErrors.profit_limit ? true : false}
                            name="profit_limit"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.profitLimitBalance`)}
                            variant="outlined"
                            value={planHistories.profit_limit_balance || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_limit_balance ? inputErrors.profit_limit_balance : ''}
                            error={inputErrors && inputErrors.profit_limit_balance ? true : false}
                            name="profit_limit_balance"
                            type="number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.percent`)}
                            variant="outlined"
                            value={planHistories.roi?.percent }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.roi?.percent ? inputErrors.roi?.percent : ''}
                            error={inputErrors && inputErrors.roi?.percent ? true : false}
                            name="percent"
                            type="number"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: 'lightgrey',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`plans.total_distributions`)}
                            variant="outlined"
                            value={planHistories.roi?.total_distributions }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.roi?.total_distributions ? inputErrors.roi?.total_distributions : ''}
                            error={inputErrors && inputErrors.roi?.total_distributions ? true : false}
                            name="total_distributions"
                            type="number"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: 'lightgrey',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`planHistories.received_times`)}
                            variant="outlined"
                            value={planHistories.roi?.received_times }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.roi?.received_times ? inputErrors.roi?.received_times : ''}
                            error={inputErrors && inputErrors.roi?.received_times ? true : false}
                            name="received_times"
                            type="text"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: 'lightgrey',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`planHistories.ROI_day`)}
                            variant="outlined"
                            value={planHistories.roi?.ROI_day || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.roi?.ROI_day ? inputErrors.roi?.ROI_day : ''}
                            error={inputErrors && inputErrors.roi?.ROI_day ? true : false}
                            name="ROI_day"
                            type="datetime"
                            onChange={handleChange}
                            sx={{
                                backgroundColor: 'lightgrey',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`planHistories.status`)}</InputLabel>
                            <Select
                                value={planHistories.status}
                                label={t(`planHistories.status`)}
                                name="status"
                                onChange={({ target }) => setPlanHistories({ ...planHistories, status: target.value })}
                                error={inputErrors && inputErrors.status ? true : false}
                            >
                                {_.map(statusList, (display, skey) => {
                                    return <MenuItem key={skey} value={skey}>{display?.[i18n.language]?display?.[i18n.language]:display?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</MenuItem>
                                })}
                            </Select>
                            {
                                inputErrors && inputErrors.status
                                ?
                                <Typography variant="caption" style={{color: theme.palette.red.main}}>{inputErrors.status}</Typography>
                                : null
                            }
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`general.admin_remark`)}
                            variant="outlined"
                            value={planHistories.admin_remark || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.admin_remark ? inputErrors.admin_remark : ''}
                            error={inputErrors && inputErrors.admin_remark ? true : false}
                            name="admin_remark"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/plan-histories`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));
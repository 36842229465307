import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Edit } from '@mui/icons-material';

import { getUrl, putUrl , postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

const INITIAL_STATE = {
    remark: '', 
}

const WalletUserWithDraw = () => {
    const [data, setData] = useState([]);
    const [inputErrors, setInputErrors] = useState({});
    const [tableLoading, setTableLoading] = useState(false);
    
    const [state, setState] = useState(INITIAL_STATE);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [walletActionDialog, setWalletActionDialog] = useState({
        open: false,
        id: 0,
        status: '',
        remark: '',
    });
    const [actionStatusList, setActionStatusList] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);

    const [currRows, setCurrRows] = useState([]);
    const [submnitDialogOpen, setSubmitDialogOpen] = useState(false);

    useEffect(() => {
        setTableLoading(true);
        callApi()

        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(actionStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key });
        })
        setStatusList(newStatusList);
        
        // eslint-disable-next-line
    }, [actionStatusList, i18n.language]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'amount') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const callApi = () => {

        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }

        getUrl(`/wallet-withdraw-request`, apiData).then(response => {
            if (response.status) {
                setData(response.data.data);
                setRowCount(response.data.total);
                setActionStatusList(response.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        })
        .catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        .finally(() => {
            setTableLoading(false);
        });

    }

    const handleWithdrawalDialogOpen = (id, status, admin_remark) => {
        setWalletActionDialog({
            open: true,
            id: id,
            status: status,
            remark: admin_remark,
        });
    }

    const handleWithdrawalDialogClose = () => {
        setInputErrors({});
        setWalletActionDialog({
            open: false,
            id: 0,
            status: '',
            remark: '',
        });
    }

    const updateWithdrawalStatus = () => {
        setLoading(true);
        setWalletActionDialog({ ...walletActionDialog, open: false });
        let updateData = {
            status: walletActionDialog.status,
            remark: walletActionDialog.remark,
        };
        putUrl(`/wallets/withdrawal/${walletActionDialog.id}`, updateData).then(response => {
            if (response.status) {
                let updateKey = _.findKey(data, function (o) { return o.id === walletActionDialog.id; });
                if (updateKey) {
                    let tempData = { ...data, [updateKey]: { ...data[updateKey], status: walletActionDialog.status, status_display: response.withdraw_status_display, remark: { ...data[updateKey]['remark'], admin_remark: walletActionDialog.remark } } };
                    setData(Object.values(tempData));
                }
                handleWithdrawalDialogClose();
                callApi()
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                setWalletActionDialog({ ...walletActionDialog, open: true });
                addAlert('', response.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setWalletActionDialog({ ...walletActionDialog, open: true });
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        .finally(() => {
            setLoading(false);
        })
        ;
    }

    const submitDatas = ({value}) => {
        
        setLoading(true);
        setInputErrors();

        let apiData = {
            ids: currRows,
            type: value,
            remark: state.remark,
        };

        postUrl('/wallets/process-withdrawal', apiData).then(response => {
            if (response.status) {
                callApi();
                setCurrRows();
                setState(INITIAL_STATE);
                setSubmitDialogOpen(false);
                if (value === 'withdrawal'){
                    addAlert('', t('success.withdrawalsSuccess'), 'success', '');
                }
                else if (value === 'refund'){
                    addAlert('', t('success.refundedSuccess'), 'success', '');
                }
            }
            else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.withdrawalsError'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })
        ;
    }

    const submutDialog = () => {
        return (
            <Dialog open={submnitDialogOpen} onClose={() => setSubmitDialogOpen(false)} fullWidth={true} maxWidth="sm">
                <DialogTitle>{t('wallets.withdrawals')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            {
                                _.map(currRows, (rowData, key) => {
                                    return (
                                        <Box key={key} padding={'10px'}>
                                            <Typography >
                                            {t(`wallets.id`)}: {_.find(data, value => value.id == key)?.id}
                                            </Typography>
                                            <Typography >
                                                {t(`wallets.finalAmount`)}: {parseFloat(_.find(data, value => value.id == key)?.final_amount).toFixed(4)}
                                            </Typography>
                                            <Typography >
                                            {t(`wallets.address`)}: {_.find(data, value => value.id == key)?.details?.address} 
                                            </Typography>
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name='remark'
                                label={t('general.remark')}
                                onChange={handleChange}
                                helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                                error={inputErrors && inputErrors.remark ? true : false}
                                InputLabelProps={{ shrink: true }}
                                value={state.remark}
                                placeholder={t('general.remark')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-around' , padding: '16px 24px'}}>
                    <Button variant="outlined" color="primary" onClick={() => setSubmitDialogOpen(false)}>{t('button.cancel')}</Button>
                    <Button variant="contained" color="primary" onClick={() => submitDatas({value: 'refund'})}>{t('button.reject')}</Button>
                    <Button variant="contained" color="primary" onClick={() => submitDatas({value: 'withdrawal'})}>{t('button.approve')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const updateStatusDialog = () => {
        return (
            <Dialog open={walletActionDialog.open} onClose={() => handleWithdrawalDialogClose()} fullWidth={true} maxWidth="sm">
                <DialogTitle>{t('wallets.updateStatusTitle')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`wallets.status`)}
                                    variant="outlined"
                                    value={walletActionDialog.status}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.status ? inputErrors.status : ''}
                                    error={inputErrors && inputErrors.status ? true : false}
                                    name="status"
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onChange={({ target }) => setWalletActionDialog({ ...walletActionDialog, status: target.value })}
                                >
                                    <option key='' value='' disabled>
                                        {t('general.pleaseSelect')}
                                    </option>
                                    {_.map(actionStatusList, (value, key) => {
                                        return (
                                            <option key={key} value={key}>
                                                {value[i18n.language] ? value[i18n.language] : value.en}
                                            </option>
                                        )
                                    }
                                    )}
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`wallets.remark`)}
                                    variant="outlined"
                                    value={walletActionDialog.remark}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                                    error={inputErrors && inputErrors.remark ? true : false}
                                    name="remark"
                                    onChange={({ target }) => setWalletActionDialog({ ...walletActionDialog, remark: target.value })}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => handleWithdrawalDialogClose()}>{t('button.cancel')}</Button>
                    <Button variant="contained" color="primary" onClick={() => updateWithdrawalStatus()}>{t('button.save')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const columns = useMemo(() => ([
        {
            header: `${t(`wallets.actionId`)}`,
            accessorKey: 'id',
        },
        // {
        //     // use for export, hide for display
        //     header: `${t(`wallets.actionType`)}`,
        //     accessorKey: 'transaction_code_display.details.' + i18n.language,
        // },
        // {
        //     // use for display, hide for export
        //     header: `${t(`wallets.actionType`)}`,
        //     accessorKey: 'transaction_code_display',
        //     Cell: ({ cell }) => (cell.row.original.transaction_code_display && (cell.row.original.transaction_code_display.details[i18n.language] ? cell.row.original.transaction_code_display.details[i18n.language] : cell.row.original.transaction_code_display.details.en)),
        //     enableSorting: false,
        //     hideExport: true,
        // },
        {
            header: `${t(`wallets.username`)}`,
            id: 'wallet_actionable.username',
            name: 'username',
            accessorFn: (row) => row.wallet_actionable.username,
            enableSorting: false,
        },
        {
            header: `${t(`wallets.originalAmount`)}`,
            id: 'original_amount',
            accessorFn: (row) => parseFloat(row.original_amount).toFixed(4),
        },
        {
            header: `${t(`wallets.finalAmount`)}`,
            id: 'final_amount',
            accessorFn: (row) => parseFloat(row.final_amount).toFixed(4),
        },
        {
            // use for display, hide for export
            header: `${t(`wallets.status`)}`,
            accessorKey: 'status',
            Cell: ({ cell }) => (
                <Box>
                    {cell.row.original.status_display.details[i18n.language] ? cell.row.original.status_display.details[i18n.language] : cell.row.original.status_display.details.en}
                    {/* {
                        cell.row.original.type === 'withdraw'
                            ?
                            <IconButton onClick={() => handleWithdrawalDialogOpen(cell.row.original.id, cell.row.original.status, cell.row.original.remark?.admin_remark ? cell.row.original.remark?.admin_remark : '')}><Edit size={16} /></IconButton>
                            :
                            null
                    } */}
                </Box>
            ),
            enableSorting: false,
            enableGlobalFilter: false,
            enableColumnFilter: false,
            // filterFn: 'equals',
            // filterSelectOptions: statusList,
            // filterVariant: 'select',
            hideExport: true,
        },
        {
            header: `${t(`wallets.details`)}`,
            accessorKey: 'details',
            Cell: ({ cell }) => (
                <Box>
                    {
                        _.map(cell.row.original.details, (content, title) => {
                            if (_.includes(['address', 'network'], title)) {
                                return (
                                    <Box key={title}>
                                        {t(`wallets.${title}`) + ": " + content}
                                    </Box>
                                )
                            }
                        })
                    }
                </Box>
            ),
            enableSorting: false,
            hideExport: true,
        },
        // {
        //     header: `${t(`general.admin_remark`)}`,
        //     accessorKey: 'remark.admin_remark',
        //     accessorFn: (row) => row.remark?.admin_remark ? row.remark?.admin_remark : '-',
        //     enableSorting: false,
        // },
        {
            header: `${t(`general.member_remark`)}`,
            accessorKey: 'remark.member_remark',
            accessorFn: (row) => row.remark?.member_remark ? row.remark?.member_remark : '-',
            enableSorting: false,
        },
        // {
        //     header: `${t(`wallets.remark`)}`,
        //     accessorKey: 'remark',
        //     Cell: ({ cell }) => (
        //         <Box>
        //             {
        //                 _.map(cell.row.original.remark, (content, title) => {
        //                     if (_.includes(['admin_remark', 'member_remark'], title)) {
        //                         if(content){
        //                             return (
        //                                 <Box key={title}>
        //                                     {t(`general.${title}`) + ": " + content}
        //                                 </Box>
        //                             )
        //                         }
        //                     }
        //                 })
        //             }
        //         </Box>
        //     ),
        //     enableSorting: false,
        // },
        // {
        //     // use for export, hide for display
        //     header: `${t(`wallets.status`)}`,
        //     accessorKey: 'status_display.details.' + i18n.language,
        //     enableSorting: false,
        // }, 
        {
            header: `${t(`general.updatedAt`)}`,
            accessorKey: 'updated_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, actionStatusList, statusList]);
    
    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.withdrawalRequestList`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.withdrawalRequestList`)}</Typography>
                </Breadcrumbs>
            </Grid>
            {updateStatusDialog()}
            {submutDialog()}
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'WalletUserWithdraw'}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['transaction_code_display.details.' + i18n.language]: false, ['status_display.details.' + i18n.language]: false }}
                rowSelection={currRows}
                onRowSelectionChange={setCurrRows}
                getRowId={(row) => row.id}
                enableSelectAll={true}
                enableRowSelection={true}
                topActions={
                    <Button 
                        variant="contained" 
                        onClick={() => setSubmitDialogOpen(true)}
                        disabled={ _.size(currRows) > 0 ? false : true }
                    >
                        {t('button.withdraw')}
                    </Button>
                }
            />
        </>
    )
}

export default WalletUserWithDraw;
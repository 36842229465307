import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

const MODULE = 'trades';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [bonusList, setBonusList] = useState([]);
    const [bonusListForSelect, setBonusListForSelect] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/trade-buy-histories`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
                setBonusList(response.data.bonus_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    useEffect(() => {
        let newList = [];
        _.map(bonusList, (data, key) => {
            let textDisplay = data.name[i18n.language] ? data.name[i18n.language] : data.name.en;
            newList.push({ text: textDisplay, value: data.id.toString() });
        })
        setBonusListForSelect(newList);
        // eslint-disable-next-line
    }, [bonusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            accessorKey: 'id',
            header: `${t('general.id')}`,
        },
        {
            accessorKey: 'trade_id',
            header: `${t('trades.trade_id')}`,
            Cell: ({ cell }) => (
                <>
                    {
                        cell.row.original.trade_id
                        ? 
                        <Link underline='none' to={`/trade-edit/${cell.row.original.trade_id}`} component={RouterLink}>
                            {cell.row.original.trade_id}
                        </Link>

                        :
                        '-'
                    }
                </>
            )
        },
        {
            accessorKey: 'plan_history_id',
            header: `${t('trades.plan_history_id')}`,
            Cell: ({ cell }) => (
                _.includes(permissions, `plan-history-edit`) ?
                    <Link underline='none' to={`/plan-history-edit/${cell.row.original.plan_history_id}`} component={RouterLink}>
                        {cell.row.original.plan_history_id}
                    </Link>
                    : null
            ),
            // enableSorting: false,
            // enableColumnFilter: false,
            // maxSize: 50,
            hideExport: true,
        },
        {
            accessorKey: 'user.username',
            header: `${t('trades.user_id')}`,
            enableSorting: false,

            Cell: ({ cell }) => (
                <>
                    {
                        cell.row?.original?.user?.username
                    }
                </>
            )
        },
        {
            accessorKey: 'purchased_price',
            header: `${t('trades.purchased_price')}`,
            Cell: ({ cell }) => (
                <>
                    {
                        cell.row.original.purchased_price
                        ? 
                        cell.row.original.purchased_price
                        :
                        '-'
                    }
                </>
            )
        },
        {
            accessorKey: 'active_unit',
            header: `${t('trades.active_unit')}`,
        },
        {
            accessorKey: 'passive_unit',
            header: `${t('trades.passive_unit')}`,
        },
        {
            accessorKey: 'split_times',
            header: `${t('trades.split_times')}`,
        },

        {
            accessorKey: 'max_split_times',
            header: `${t('trades.max_split_times')}`,
        },
        {
            accessorKey: 'status',
            header: `${t('trades.status')}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>
                {
                    statusList.find(item => item.value == cell.row.original.status)?.text
                }
                </>
            ),
            // exportKey: `status_display.${i18n.language}`
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList, bonusListForSelect]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.ReportTradeBuyHistories`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.ReportTradeBuyHistories`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'TradeBuyHistories'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default Listing
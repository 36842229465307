import _ from 'lodash';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';
import { MdDownload } from "react-icons/md";

import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

// 'material-react-table': https://www.material-react-table.com/
// 'react-csv': https://www.npmjs.com/package/react-csv
// columns must have accessorKey or accessorFn,id pair

/**
 * data with translation: need two columns, one for export, one for display 
 * for display: use cell, hide for export - hideExport: true at columns
 * for export : hide table column - columnVisibility: { firstName: false }
 */

const CustomizeMaterialReactTable = ({ data, columns, isLoading, exportFile, exportFileName, columnVisibility }) => {
    const { t, i18n } = useTranslation();
    const [pagination] = useState({ pageSize: 20, pageIndex: 0 });
    const [excelHeaders, setExcelHeaders] = useState([]);

    useEffect(() => {
        let headers = [];
        _.map(columns, value => {
            if (!value.hideExport) {
                headers.push({ label: value.header, key: value.accessorKey ? value.accessorKey : value.id });
            }
        })
        setExcelHeaders(headers);
        // eslint-disable-next-line
    }, [columns]);

    const today = new Date();
    const datetime_now = today.getFullYear() +
        ((today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : `${(today.getMonth() + 1)}`) +
        ((today.getDate()) < 10 ? `0${(today.getDate())}` : `${(today.getDate())}`);

    return (
        <>
            {/* {
                _.size(data) > 0
                    ?
                    null
                    :
                    < Box style={{ margin: 20, textAlign: "center" }}>
                        {t('general.noData')}
                    </Box>
            } */}
            <MaterialReactTable
                columns={columns}
                data={data}

                initialState={{
                    showColumnFilters: true,
                    pagination: pagination,
                }}
                state={{ isLoading, columnVisibility }}
                localization={i18n.language === 'cn' ? MRT_Localization_ZH_HANS : MRT_Localization_EN}

                // disabled functions
                enableFullScreenToggle={false}
                enableGlobalFilter={false}
                enableHiding={false}
                enableDensityToggle={false}

                // EXPORT
                positionToolbarAlertBanner="bottom"
                renderToolbarTopCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        {
                            exportFile
                                ?
                                <CSVLink
                                    data={(table.getPrePaginationRowModel().rows).map((row) => row.original)}
                                    headers={excelHeaders}
                                    filename={exportFileName + `_` + datetime_now + `.csv`}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                                        startIcon={<MdDownload />}
                                        variant="contained"
                                    >
                                        {t('button.exportData')}
                                    </Button>
                                </CSVLink>
                                :
                                null
                        }
                    </Box>
                )}
            />
        </>
    )
};

export default CustomizeMaterialReactTable
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiArrowLeft } from "react-icons/fi";

import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';

export default function Detail() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [state, setState] = useState({
        name: '',
        parent_id: 0,
        assignRole: [],
    });
    const [mainPermission, setMainPermission] = useState({});
    const [role, setRole] = useState({});
    const [inputErrors, setInputErrors] = useState({});
    let navigate = useNavigate();
    let { id } = useParams();
    const location = useLocation();
    const formType = location.pathname === `/permission-add` ? "add" : "edit";

    useEffect(() => {
        setLoading(true);
        if (id) {
            getUrl(`/permissions/${id}`).then(response => {
                setLoading(false);
                if (response.status) {
                    setState(response.data.permission);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setState({
                name: '',
                parent_id: 0,
                assignRole: [],
            });
        }
        getUrl('/permissions').then(permissionList => {
            setLoading(false);
            if (permissionList.status) {
                setMainPermission(permissionList.data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        getUrl('/roles').then(roleList => {
            setLoading(false);
            if (roleList.status) {
                setRole(roleList.data.listing);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [addAlert, id])


    const submitData = () => {
        setLoading(true);
        setInputErrors([]);
        let apiData = {
            name: state.name,
            parent_id: state.parent_id,
        }
        if (formType === 'edit' && id) {
            putUrl(`/permissions/${id}`, apiData).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.editSuccess'), 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', t('error.editError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            apiData['roles'] = state.assignRole;
            postUrl('/permissions', apiData).then(response => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/permission-edit/${response.data.id}`, { replace: true });
                } else {
                    setInputErrors(response.errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    const authorizeRole = roleName => {
        let roleAuthorize = state.assignRole;

        // const index = roleAuthorize.indexOf(roleAuthorize);
        if (_.includes(roleAuthorize, roleName)) {
            // roleAuthorize.splice(index, 1);
            // _.remove(roleAuthorize, function(name) {
            //     return 
            // });

            roleAuthorize = _.difference(roleAuthorize, [roleName]);
        } else {
            roleAuthorize.push(roleName);
        }

        setState({ ...state, assignRole: roleAuthorize });
    }

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.permission${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/permissions`}>
                            {t(`title.permissions`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.permission${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box  noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('permissions.categoryBelongTo')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.parent_id}
                                    label={t('permissions.categoryBelongTo')}
                                    onChange={({ target }) => setState({ ...state, parent_id: target.value })}
                                    disabled={formType === 'edit' ? true : false}
                                >
                                    <MenuItem key={0} value={0}>--- {t('permissions.mainPermission')} --- </MenuItem>
                                    {
                                        _.size(mainPermission) > 0 && _.map(mainPermission, permissionType => {
                                            if (permissionType.parent_id === 0) {
                                                return <MenuItem key={permissionType.id} value={permissionType.id}>{permissionType.name}</MenuItem>
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box  noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('permissions.name')}
                                variant="outlined"
                                value={state.name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                            />
                        </Box>
                    </Grid>
                    {
                        _.size(role) > 0 && <Grid item xs={12}>
                            <Box  noValidate
                                sx={{ '& > :not(style)': { m: 1, width: '100%' }, flexGrow: 1 }} >
                                <Typography>{t('permissions.assignPermission')}</Typography>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {
                                        _.map(role, roleItem => {
                                            return (
                                                <Grid item xs={4} key={roleItem.id}>
                                                    <FormControlLabel
                                                        control={<Checkbox color="primary" checked={_.includes(state.assignRole, roleItem.name)} onChange={() => authorizeRole(roleItem.name)} disabled={formType === 'edit' ? true : false} />}
                                                        label={roleItem.display_name[i18n.language]}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                    }
                </Grid>
                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/permissions`} component={RouterLink}>
                        <Button size="large" variant="outlined" style={{ marginRight: 15 }} className={styles.buttonStyle}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" className={styles.buttonStyle} onClick={() => submitData()}>{formType === 'edit' ? t('button.edit') : t('button.save')}</Button>
                </Box>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}));
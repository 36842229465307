import _, { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Close, Image, TextFields } from '@mui/icons-material';
import { Button, Card, CardActionArea, CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';


const NoticeItem = ({ info, dialogInfo, setDialogInfo }) => {
    const { i18n } = useTranslation();

    return (
        <div style={{ whiteSpace: 'nowrap', display: 'inline-block', margin: 10 }}>
            <Card style={{ width: 200 }}>
                <CardActionArea onClick={() => setDialogInfo({ ...dialogInfo, open: true, dialogId: info?.id })}>
                    <CardContent>
                        {
                            info?.content_type === "image" ?
                                <Image /> : <TextFields />
                        }
                        <Typography variant="h6" color="primary" noWrap>{info?.title[i18n.language] ? info?.title[i18n.language] : info?.title?.cn}</Typography>
                        <Typography variant="body2">{info?.created_at || '-'}</Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}

const NoticeDialog = ({ dialogInfo, setDialogInfo }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [info, setInfo] = useState({});
    const { addAlert, setLoading, loading } = useNotificationLoading();

    useEffect(() => {
        if (dialogInfo?.dialogId !== null) {
            setLoading(true);

            getUrl(`/notice-info/${dialogInfo?.dialogId}`).then(response => {
                setLoading(false);
                if (response.status) {
                    setInfo(response.data);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        // eslint-disable-next-line
    }, [dialogInfo?.dialogId]);

    const getLangContent = () => {

        if (info?.content_type === "image") {
            let imageDetail = '/images/login_image.png';
            if (_.size(info?.content_images) > 0) {
                let tempData = _.find(info?.content_images, { 'type': `notice_${i18n.language}` });
                if (!isUndefined(tempData)) {
                    imageDetail = tempData?.file_name;
                } else {
                    let defaultData = _.find(info?.content_images, { 'type': `notice_cn` });
                    if (defaultData) {
                        imageDetail = defaultData?.file_name;
                    }
                }
            }

            return <img src={imageDetail} alt="Notice" style={{ objectFit: 'cover', height: '100%', width: '100%' }} />;

        } else if (info?.content_type === "text") {
            if (info?.content[i18n.language] && info?.content[i18n.language] !== null) {
                return (
                    <div dangerouslySetInnerHTML={{ __html: info?.content[i18n.language] }} />
                )
            } else {
                return (<div dangerouslySetInnerHTML={{ __html: info?.content?.cn }} />
                )
            }

        } else {
            return (
                <div>
                    <Typography variant="h5">{t('general.noData')}</Typography>
                </div>
            )
        }

    }

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={dialogInfo?.open}
            onClose={() => setDialogInfo({ ...dialogInfo, open: false, dialogId: null })}
            sx={{
                background: 'transparent',
                '& .MuiPaper-root': {
                    background: info?.content_type === "image" ? 'transparent' : 'white',
                    boxShadow: 'none',
                },
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                }
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                <IconButton
                    onClick={() => setDialogInfo({ ...dialogInfo, open: false, dialogId: null })}
                    sx={{
                        ':hover': {
                            backgroundColor: 'transparent',
                        }
                    }}
                >
                    <Close style={{ color: info?.content_type === "image" ? 'white' : theme.palette.primary.main }} fontSize="large" />
                </IconButton>
            </div>
            {
                info?.content_type === "text" &&
                <DialogTitle>
                    <Typography variant="h6" color="primary" noWrap>{info?.title[i18n.language] ? info?.title[i18n.language] : info?.title?.cn}</Typography>
                </DialogTitle>
            }
            <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                {
                    loading ?
                        null : (
                            info?.content_type === "image" ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', height: '80%' }}>
                                    {getLangContent()}
                                </div>
                                : (
                                    info?.content_type === "text" ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', height: '100%' }}>
                                            {getLangContent()}
                                        </div>
                                        :
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', height: '100%' }}>
                                            <div>
                                                <Typography variant="h5">{t('general.noData')}</Typography>
                                            </div>
                                        </div>
                                )
                        )
                }
            </DialogContent>
        </Dialog>
    )

}

export default function Dashboard() {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    let navigate = useNavigate();
    const [dialogInfo, setDialogInfo] = useState({
        open: false,
        dialogId: null,
    })
    const [state, setState] = useState({
        announcements: [],
        news: [],
        newMembers: [],
        newPlanHistories: [],
        newDepositWithdrawal: [],
    });

    useEffect(() => {
        setLoading(true);
        getUrl('/admins/dashboard-summary').then(response => {
            setLoading(false);
            if (response.status) {
                setState({
                    ...state,
                    announcements: response?.data?.announcements,
                    news: response?.data?.news,
                    newMembers: response?.data?.new_members,
                    newPlanHistories: response?.data?.new_plan_histories,
                    newDepositWithdrawal: response?.data?.new_deposit_withdrawal,
                });
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div style={{ minHeight: '100vh' }}>
            <div style={{ paddingBottom: 5 }}>
                <div style={{ paddingBottom: 5 }}>
                    <Typography variant="h4" component="h1">{t("title.dashboard")}</Typography>
                </div>
                {
                    _.includes(permissions, "report-monthly") &&
                    <Grid display="flex" justifyContent="flex-end">
                        <Button size="small" onClick={() => navigate("/monthly-report")}>{t('dashboard.moreReports')}</Button>
                    </Grid>
                }
                <Grid container spacing={2} display="flex" justifyContent="space-evenly" textAlign="center" paddingTop="10px" paddingBottom="20px">
                    {_.map([
                        { title: t('dashboard.newDepositWithdrawDaily'), value: 'today' },
                        { title: t('dashboard.newDepositWithdrawWeekly'), value: 'weekly' },
                    ], (data, idx) => {
                        return (
                            <Grid item xs={12} md={6} key={idx}>
                                <div className={classes.boxDesign}>
                                    <p>{data.title}</p>
                                    <hr style={{ marginLeft: 20, marginRight: 20, height: 2, background: "#80808066" }} />
                                    <Grid container display="flex" justifyContent="space-evenly">
                                        <div>
                                            <p>{t('dashboard.deposit')}</p>
                                            <p className={classes.textDesign}>{currencyFormat(state.newDepositWithdrawal?.[data.value]?.['deposit'] * 1 || 0, 2)}</p>
                                        </div>
                                        <div>
                                            <p>{t('dashboard.withdrawal')}</p>
                                            <p className={classes.textDesign}>{currencyFormat(state.newDepositWithdrawal?.[data.value]?.['withdrawal'] * 1 || 0, 2)}</p>
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
                <hr style={{ height: 2, background: "#80808066" }} />
                <Grid container spacing={2} display="flex" justifyContent="space-evenly" textAlign="center" paddingY="20px">
                    {_.map([
                        { title: t('dashboard.newMemberDaily'), value: state.newMembers?.['today'] },
                        { title: t('dashboard.newPlanHistoryDaily'), value: state.newPlanHistories?.['today'] },
                        { title: t('dashboard.newMemberWeekly'), value: state.newMembers?.['weekly'] },
                        { title: t('dashboard.newPlanHistoryWeekly'), value: state.newPlanHistories?.['weekly'] },
                    ], (data, idx) => {
                        return (
                            <Grid item xs={12} md={3} key={idx}>
                                <div className={classes.boxDesign}>
                                    <p>{data.title}</p>
                                    <p className={classes.textDesign}>{data.value || 0}</p>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
                {/* ANNOUCEMENTS AND NEWS */}
                {
                    _.size(state?.announcements) > 0 &&
                    <div style={{ gap: 20, display: 'flex', flexDirection: 'column', paddingBottom: 20 }}>
                        <Typography variant="body2">{t('notice.type_announcement')}</Typography>
                        <div className={classes.scrollContainer}>
                            {
                                _.map(state?.announcements, (data, dataIndex) => {
                                    return (
                                        <NoticeItem info={data} key={dataIndex} dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    _.size(state?.news) > 0 &&
                    <div style={{ gap: 20, display: 'flex', flexDirection: 'column', paddingBottom: 20 }}>
                        <Typography variant="body2">{t('notice.type_news')}</Typography>
                        <div className={classes.scrollContainer}>
                            {
                                _.map(state?.news, (data, dataIndex) => {
                                    return (
                                        <NoticeItem info={data} key={dataIndex} dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                <NoticeDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    scrollContainer: {
        display: 'flex',
        gap: 10,
        width: '100%',
        overflowX: 'auto',
    },
    boxDesign: {
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: 10,
        boxShadow: `2px 2px 7px 0px #80808066`
    },
    textDesign: {
        fontSize: 20,
        fontWeight: 'bold'
    }
}))
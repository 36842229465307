import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { Box, Breadcrumbs, Grid, Link, Typography, IconButton, Dialog, Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Close } from '@mui/icons-material';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';
import moment from "moment";

const ThirdPartyPaymentOrderListing = () => {
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [state, setState] = useState({
        start_date_time:moment().subtract(1, 'months').format('YYYY-MM-DD'),
        end_date_time:moment().format('YYYY-MM-DD'),
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [options, setOptions] = useState([]);
    const [values, setValues] = useState([]);
    const [walletList, setWalletList] = useState([]);
    const [tradeRedeemable, setTradeRedeemable] = useState([]);
    const [tradeTransaction, setTradeTransaction] = useState([]);
    const [selected, setSelected] = useState({
        ulid: '',
        wallets: '',
        redeemable: '',
        transaction: '',
    });
    const [statusList, setStatusList] = useState([]);
    const [open, setOpen] = useState(false);

    // ------------------- API --------------------
    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
            start_date: state.start_date_time ,
            end_date: state.end_date_time ,
        }
        getUrl(`/third-party-payment-order`, apiData).then(response => {
            setTableLoading(false);
            // console.log(response);
            if (response.status) {
                setData(response.data.listing.data);
                setTradeRedeemable(response.data.trade_redeemable);
                setTradeTransaction(response.data.trade_transaction);
                setRowCount(response.data.listing.total);
                setOptions(response.status_listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData, state]);

    useEffect(() => {
        setTableLoading(true);
        getUrl(`/wallets`).then(response => {
            setTableLoading(false);
            if (response.status) {
                setValues(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, []);

    useEffect(() => {
        let newStatusList = [];
        _.map(options, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);

        let newWalletsList = [];
        _.map(values, (value, key) => {
            let textDisplay = value?.name[i18n.language] ? value?.name[i18n.language] : value?.name.en;
            newWalletsList[value?.code] = { text: textDisplay, decimal: value?.decimal};
        })
        setWalletList(newWalletsList);
        // eslint-disable-next-line
    }, [options, i18n.language]);

    // ---------------------- TABLE COLUMN DISPLAY DATA ------------------------
    const columns = useMemo(() => ([
        // {
        //     header: `${t(`thirdPartyPaymentOrder.id`)}`,
        //     id: 'id',
        //     accessorFn: (row) => (row.id),
        //     enableSorting: false,
        //     maxSize: 25,
        // },
        {
            header: `${t(`thirdPartyPaymentOrder.ulid`)}`,
            id: 'ulid',
            accessorFn: (row) => (row.ulid),
            enableSorting: false,
            maxSize: 25,
        },
        {
            header: `${t(`thirdPartyPaymentOrder.username`)}`,
            id: 'user.username',
            accessorFn: (row) => (row.user?.username),
            enableSorting: false,
            Cell: ({ cell }) => (
                <>
                    {
                        _.includes(permissions, `member-edit`)
                            ? <Link underline='none' to={`/member-edit/${cell.row.original.user.id}`} component={RouterLink}>
                                {cell.row?.original?.user?.username ? cell.row?.original?.user?.username : '-'}
                            </Link>
                            : <>{cell.row?.original?.user?.username ? cell.row?.original?.user?.username : '-'}</>
                    }
                </>
            )
        },
        {
            // use for display, hide for export
            header: `${t(`thirdPartyPaymentOrder.merchant`)}`,
            id: 'merchant.name',
            accessorFn: (row) => (<>{row.merchant?.name?.[i18n.language]} ({row.merchant?.code})</>),
            enableSorting: false,
            Cell: ({ cell }) => {
                if (cell.row.original.merchant_id !== 0) {
                    return (
                        <>
                            {
                                _.includes(permissions, `merchant-edit`)
                                    ? <Link underline='none' to={`/merchant-edit/${cell.row.original.merchant_id}`} component={RouterLink}>
                                        {cell.row?.original?.merchant?.name?.[i18n.language]}{` (${cell.row?.original?.merchant?.code})`}
                                    </Link>
                                    : <>{cell.row?.original?.merchant?.name?.[i18n.language]}{` (${cell.row?.original?.merchant?.code})`}</>
                            }
                        </>
                    )
                } else {
                    return (<>{t(`merchant.system`)}</>)
                }
            },
            hideExport: true,
        },
        {
            // use for export, hide for display
            header: `${t(`thirdPartyPaymentOrder.merchantName`)}`,
            accessorKey: `merchant.name.${i18n.language}`,
        },
        {
            // use for export, hide for display
            header: `${t(`thirdPartyPaymentOrder.merchantCode`)}`,
            accessorKey: `merchant.code`,
        },
        {
            header: `${t(`thirdPartyPaymentOrder.title`)}`,
            id: 'title',
            accessorKey: 'title',
            enableSorting: false,
        },
        {
            header: `${t(`thirdPartyPaymentOrder.detail`)}`,
            id: 'detail',
            accessorKey: 'detail',
            enableSorting: false,
        },
        {
            // use for display, hide for export
            header: `${t(`thirdPartyPaymentOrder.amount.amount`)}`,
            id: 'amount',
            accessorKey: 'amount',
            Cell: ({ cell }) => (
                <Tooltip title={t('button.view')}>
                    <div onClick={() => handleClickOpen(cell.row.original.ulid)} style={{ cursor: 'pointer' }}>
                        {
                            _.map(cell.row.original.amount, (detail, key) => (
                                <div key={key}>
                                    {key === 'TRADEVALUE' ? t(`thirdPartyPaymentOrder.amount.tradeValue`) : key}: {key !== 'TRADEVALUE' && '$'}{(detail).toFixed(2)}
                                </div>
                            ))
                        }
                    </div> 
                </Tooltip>
            ),
            enableSorting: false,
            enableColumnFilter: false,
            hideExport: true,
        },
        {
            header: `${t(`thirdPartyPaymentOrder.paymentId`)}`,
            id: 'payment_id',
            accessorFn: (row) => (row?.payment_id),
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            header: `${t(`thirdPartyPaymentOrder.rate`)}`,
            id: 'rate',
            accessorFn: (row) => (row?.rate),
            enableSorting: false,
        },
        {
            // use for display, hide for export
            header: `${t(`thirdPartyPaymentOrder.status`)}`,
            accessorKey: 'status',
            Cell: ({ cell }) => (
                <>{t(`thirdPartyPaymentOrder.statusInfo.${cell.row.original.status}`)}</>
            ),
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            hideExport: true,
        },
        {
            accessorKey: 'timeout_date',
            header: `${t('thirdPartyPaymentOrder.timeoutDate')}`,
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
            enableSorting: false,
            enableColumnFilter: false,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    // --------------------- FUNCTIONS -------------------------
    const handlePageChange = (data) => {
        setRowSelection();
        setPagination(data);
    }

    function disableEndDates(date) {
        if (state.start_date_time !== '') {
            if (date.isSameOrAfter(state.start_date_time)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true
        }
    }

    const handleClickOpen = (value) => {
        let redeemable = _.find(tradeRedeemable, (item) => item?.details?.order_id === value);
        let transaction = _.find(tradeTransaction, (item) => item?.details?.order_id === value);
        let wallets = _.find(data, (item) => item?.ulid === value);
        setSelected({
            ulid: value,
            wallets: wallets?.amount,
            redeemable: redeemable,
            transaction: transaction,
        });
        setOpen(true);
    };

    const handleClose = () => {
        setSelected([]);
        setOpen(false);
    };

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.thirdPartyPaymentOrder`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.thirdPartyPaymentOrder`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems="center" style={{ paddingBottom: 5 }}>
                <div style={{ margin: '10px 10px 10px 0px' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker disableFuture={true} value={state.start_date_time !==''?moment(state.start_date_time):moment()} label={t('thirdPartyConvert.startDate')} views={['month', 'year', 'day']} disableHighlightToday={true} onChange={(newValue) => setState({ ...state, start_date_time: moment(newValue).format('YYYY-MM-DD') })} defaultValue={moment(moment().format("YYYY-MM-DD"))} />
                    </LocalizationProvider>
                </div>
                <div style={{ margin: '10px 10px 10px 0px' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment} >
                        <DatePicker disableFuture={true} value={state.end_date_time !==''?moment(state.end_date_time):moment()} disableHighlightToday={true} shouldDisableDate={disableEndDates} label={t('thirdPartyConvert.endDate')} views={['month', 'year', 'day']} onChange={(newValue) => setState({ ...state, end_date_time: moment(newValue).format('YYYY-MM-DD') })} defaultValue={moment(moment().format("YYYY-MM-DD"))} />
                    </LocalizationProvider>
                </div>

            </Grid>

            
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'ThirdPartyPaymentOrderList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={(data) => handlePageChange(data)}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{
                    [`merchant.name.${i18n.language}`]: false,
                    [`merchant.code`]: false,
                }}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'xs'}
            >
                <div style={{ padding: '20px 30px 40px' }}>
                    <div style={{ width: '100%', textAlign: 'end' }}>
                        <IconButton style={{ padding: '0px 0px 10px 0px' }} onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </div>

                    <Typography variant='body1'><b>{t('general.ulid')}</b></Typography>
                    <Typography variant='body2'>{selected?.ulid}</Typography>
                    <br/>

                    <Typography variant='body1'><b>{t('title.wallets')}</b></Typography>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            {
                                _.map(selected?.wallets, (walletDetail, key) => {
                                    if (walletList?.[key]) {
                                        return (
                                            <tr key={key}>
                                                <td><Typography variant='body2'>{walletList?.[key]?.text}</Typography></td>
                                                <td><Typography variant='body2' style={{ textAlign: 'end' }}>$ {(walletDetail).toFixed(walletList?.[key]?.decimal)}</Typography></td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>
                    <br/>

                    <Typography variant='body1'><b>{t('title.reportTradeTransaction')}</b></Typography>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td><Typography variant='body2'>{t('tradeProfile.activeUnit')}</Typography></td>
                                <td><Typography variant='body2' style={{ textAlign: 'end' }}>{selected?.transaction?.active_unit || 0} {t('general.unit')}</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography variant='body2'>{t('tradeProfile.passiveUnit')}</Typography></td>
                                <td><Typography variant='body2' style={{ textAlign: 'end' }}>{selected?.transaction?.passive_unit || 0} {t('general.unit')}</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography variant='body2'>{t('tradeProfile.lockValue')}</Typography></td>
                                <td><Typography variant='body2' style={{ textAlign: 'end' }}>{selected?.transaction?.lock_value || (0).toFixed(3)}</Typography></td>
                            </tr> 
                        </tbody>
                    </table>
                    <br/>

                    <Typography variant='body1'><b>{t('title.reportRedeemableTransaction')}</b></Typography>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td><Typography variant='body2'>{t('tradeRedeemable.redeemableValue')}</Typography></td>
                                <td><Typography variant='body2' style={{ textAlign: 'end' }}>{selected?.redeemable?.redeemable_value || (0).toFixed(3)}</Typography></td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </Dialog>
        </>
    )
}

export default ThirdPartyPaymentOrderListing
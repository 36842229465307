import React, { useState , useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams , useSearchParams , useNavigate} from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { postUrl , getUrl, putUrl } from '@utils/ApiAction';

import { Link, TextField, Box, Button, Breadcrumbs, Grid, Typography, Divider, InputAdornment, IconButton , FormControl , InputLabel , Select , MenuItem , FormHelperText ,Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import BackToPrevious from '@layouts/BackToPrevious';
import { Add, Edit, DeleteForever, Close } from '@material-ui/icons';

const MODULE = 'trades';
const INITIAL_STATE = {
    type: '',
    code: '',
    description: '',
    value_code: '',
    extra_split_count: '',
    split_ratio: '',
    split_date: '',
    split_price: '',
    status: '',

    fee_amount: 0,
    fee_type: 'percent',
    wallet: {}
}

const INITIAL_STATE_DIALOG = {
    open: false,
    type: '',
    data: [],
    label: '',
    id: '',
    percent: '',
    value: '',
    key: '',
    percentNote: ''
}

const TradeSystem = () => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const [state, setState] = useState(INITIAL_STATE);
    const [dialog, setDialog] = useState(INITIAL_STATE_DIALOG);

    const [statusDisplay, setStatusDisplay] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [tradingEmergencyStop, setTradingEmergencyStop] = useState(50);
 
    const [feeType, setFeeType] = useState([
        { 'code': 'fixed', 'label': t(`general.fixed`) },
        { 'code': 'percent', 'label': t(`general.percent`)}
    ]);


    useEffect (() => {
        callApi();
        
        // eslint-disable-next-linex
    }, []);
    
    const callApi = () => {
        getUrl('/get-trade-setting' ).then(response => {
            if (response.status)
            {
                setState({
                    ...state,
                    // type: response.trade_system.type,
                    // code: response.trade_system.code,
                    // description: response.trade_system.description,
                    value_code: response.trade_system.value.code,
                    extra_split_count: response.trade_system.value.extra_split_count,
                    split_ratio: response.trade_system.value.split_ratio,
                    split_date: response.trade_system.value.split_date,
                    split_price: response.trade_system.value.split_price?response.trade_system.value.split_price:0,
                    status: response.trade_system.value.status,

                    fee_amount: response.trad_profit.value.fee_amount,
                    fee_type: response.trad_profit.value.fee_type,
                    wallet: response.trad_profit.value.wallet,
                });
                setTradingEmergencyStop(parseInt(response.trade_system.value.status));
                setStatusDisplay(response.status_list);
                setWallets(response.wallet_list);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })
        ;
    }

    const handleDialogClose = () => {
        setDialog(INITIAL_STATE_DIALOG);
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile' || name === 'split_price') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }else if (name === 'username') {
            value = value.replace(/[^\w\s]/gi, "").toLowerCase();
        }
        setState({ ...state, [name]: value });
    };

    const handleDialogOpen = (type, key, id, percent) => {
        let data = [];
        let label = '';
        let percentNote = '';
        if(type === 'wallets'){
            data = wallets;
            label = t(`${MODULE}.wallets`);
        }

        setDialog({
            open: true,
            type: type,
            data: data,
            label: label,
            id: id,
            percent: type !== 'limits' ? percent : '',
            value: type === 'limits' ? percent : '',
            key: key,
            percentNote: percentNote
        });
    }

    const dialogAddEdit = () => {
        let tempData = [];
        let repeat = false;
        if (dialog.type === 'wallets') {
            tempData = state.wallet;
        } 
        
        if (isNaN(dialog.key)) {
            //  add
            // repeat = Object.fromEntries(Object.entries(tempData).filter(([key]) => parseInt(key) !== parseInt(dialog.id))) ? true : false
            _.map(tempData, (data, key) => {
                if (parseInt(key) === parseInt(dialog.id)) {
                    repeat = true;
                }
            });
            if ( repeat ) 
            {
                addAlert('', t(`${MODULE}.duplicatedID`) , 'error', '');
            } 
            else 
            {
                if (dialog.id !== 'select' && dialog.type === 'wallets') {
                    setState({ ...state, wallet: { ...state.wallet, [dialog.id]: parseInt(dialog.percent)} });
                    handleDialogClose();
                } 
                else 
                {
                    addAlert('', t('bonus.fillAllColumn'), 'error', '');
                }
            }
        } else { 
            // edit
            _.map(tempData, (data, key) => {
                if (dialog.key !== key && parseInt(key) === parseInt(dialog.id)) {
                    repeat = true;
                }
            });
            if (repeat) {
                addAlert('', t(`${MODULE}.duplicatedID`), 'error', '');
            } 
            else {
                tempData = Object.fromEntries(Object.entries(tempData).filter(([key]) => parseInt(key) !== parseInt(dialog.key)))
                setState({ ...state, wallet: { ...tempData, [dialog.id]: parseInt(dialog.percent)} });
                handleDialogClose();
            }
        }
    }

    const dialogDelete = (type, id) => {
        if(type === 'wallets'){
            let tempData = state.wallet;
            tempData = Object.fromEntries(Object.entries(tempData).filter(([key]) => parseInt(key) !== parseInt(id)))
            setState({...state, wallet: tempData});
        }
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            // type: state.type,
            // code: state.code,
            description: state.description,
            value_code: state.value_code,
            extra_split_count: state.extra_split_count,
            split_ratio: state.split_ratio,
            split_date: state.split_date,
            split_price: state.split_price?state.split_price:0,
            status: state.status,
           
            fee_amount: state.fee_amount,
            fee_type: state.fee_type,
            wallet: state.wallet,
        }

        putUrl(`/update-trade-setting`, params).then(response => {
            setLoading(false);
            if (response.status) {
                
                setState({
                    ...state,
                    // type: response.trade_system.type,
                    // code: response.trade_system.code,
                    // description: response.trade_system.description,
                    value_code: response.trade_system.value.code,
                    extra_split_count: response.trade_system.value.extra_split_count,
                    split_ratio: response.trade_system.value.split_ratio,
                    split_date: response.trade_system.value.split_date,
                    split_price: response.trade_system.value.split_price?response.trade_system.value.split_price:0,
                    status: response.trade_system.value.status,

                    fee_amount: response.trad_profit.value.fee_amount,
                    fee_type: response.trad_profit.value.fee_type,
                    wallet: response.trad_profit.value.wallet,
                })
                setTradingEmergencyStop(parseInt(response.trade_system.value.status));
                setStatusDisplay(response.status_list)

                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const stopTrading = () => {
        setLoading(false);

        let callApi = {

        };

        putUrl(`/stop-trading`, callApi).then(response => {
            setLoading(false);
            if (response.status) {
                setState({...state, status: response.trade_system_status});
                setTradingEmergencyStop(parseInt(response.trade_system_status));
                addAlert('', t('trades.stopTrading'), 'success', '');
            } else {
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.tradeSystem`)}</b></Typography>
                <div>
                    <Button 
                        autoFocus 
                        variant="contained" 
                        onClick={stopTrading}
                        style={{color: "#fff", backgroundColor: tradingEmergencyStop === 50 ? "#ababab" : "#d32f2f"}}
                        disabled={ tradingEmergencyStop === 50 ? true : false }
                    >
                        {t('button.stop')}
                    </Button>
                    {/* <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/members`}>
                            {t(`title.member`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.memberAdd`)}</Typography>
                    </Breadcrumbs> */}
                </div>
            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                {/* trade_system code */}
                {/* <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.code`)}
                            variant="outlined"
                            value={state.code}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid> */}

                {/* trade_system description */}
                {/* <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.description`)}
                            variant="outlined"
                            value={state.description}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.description ? inputErrors.description : ''}
                            error={inputErrors && inputErrors.description ? true : false}
                            name="description"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid> */}

                {/* trade_system type */}
                {/* <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.type`)}
                            variant="outlined"
                            value={state.type}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.type ? inputErrors.type : ''}
                            error={inputErrors && inputErrors.type ? true : false}
                            name="type"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid> */}

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.value_code`)}
                            variant="outlined"
                            value={state?.value_code}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.value_code ? inputErrors.value_code : ''}
                            error={inputErrors && inputErrors.value_code ? true : false}
                            name="value_code"
                            onChange={handleChange}
                            disabled={true}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.extra_split_count`)}
                            variant="outlined"
                            value={state?.extra_split_count}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.extra_split_count ? inputErrors.extra_split_count : ''}
                            error={inputErrors && inputErrors.extra_split_count ? true : false}
                            name="extra_split_count"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.split_ratio`)}
                            variant="outlined"
                            value={state?.split_ratio}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.split_ratio ? inputErrors.split_ratio : ''}
                            error={inputErrors && inputErrors.split_ratio ? true : false}
                            name="split_ratio"
                            onChange={handleChange}
                        />
                        
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            type="date"
                            label={t(`${MODULE}.split_date`)}
                            variant="outlined"
                            value={state?.split_date || ''}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.split_date ? inputErrors.split_date : ''}
                            error={inputErrors && inputErrors.split_date ? true : false}
                            name="split_date"
                            onChange={handleChange}
                        />
                        
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.splitPrice`)}
                            variant="outlined"
                            value={state?.split_price}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.split_price ? inputErrors.split_price : ''}
                            error={inputErrors && inputErrors.split_price ? true : false}
                            name="split_price"
                            onChange={handleChange}
                        />
                        
                    </Box>
                </Grid>
              
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth error={inputErrors && inputErrors.placement_position ? true : false}>
                            <InputLabel>{t(`${MODULE}.status`)}</InputLabel>
                            <Select
                                value={ state?.status }
                                label={t(`${MODULE}.status`)}
                                name="status"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem key={0} value={0}>--- {`${t('general.pleaseSelect')} ${t(`${MODULE}.status`)} `} --- </MenuItem>
                                {
                                    _.map(statusDisplay, (data, key) => {
                                        return <MenuItem key={key} value={key}>{data[i18n.language]}</MenuItem>
                                    })
                                }
                            </Select>
                            {
                                inputErrors && inputErrors?.status &&
                                <FormHelperText style={{ color: 'red' }}>{inputErrors?.status}</FormHelperText>
                            }
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>

            <Divider />

            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingTop: 15 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.tradeProfit`)}</b></Typography>
            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: '5%' , paddingTop: '5%'}}>
                
                <Grid item xs={12} sm={6} md={4}>
                    <Box  noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.fee_amount`) + ( state?.fee_type === 'percent' ? ' (%)' : '')}
                            variant="outlined"
                            value={state?.fee_amount}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.fee_amount ? inputErrors.fee_amount : ''}
                            error={inputErrors && inputErrors.fee_amount ? true : false}
                            name="fee_amount"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`${MODULE}.fee_type`)}</InputLabel>
                            <Select
                                value={state?.fee_type}
                                label={t(`${MODULE}.fee_type`)}
                                name="fee_type"
                                onChange={handleChange}
                                error={inputErrors && inputErrors?.feeType ? true : false}
                            >
                                {/* <MenuItem key={""} value={""}>{t(`general.pleaseSelect`)}</MenuItem> */}
                                {
                                    _.map(feeType, serviceFeeType => {
                                        return <MenuItem key={serviceFeeType.code} value={serviceFeeType.code}>{serviceFeeType.label}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors?.['service_fee.type'] ? inputErrors?.['service_fee.type'] : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t('bonus.wallets')}</Typography>
                        {
                            _.map(state?.wallet, (data, key) => {
                                let wallet_name = '';

                                if (wallets)
                                    wallet_name = _.find(wallets, wallet_data => wallet_data?.id == key);
                                
                                return (
                                    <Box key={key} >

                                        <IconButton 
                                            color="primary" 
                                            onClick={() => handleDialogOpen('wallets', key, key, data)}
                                        >
                                            <Edit />
                                        </IconButton>

                                        <IconButton 
                                            color="primary" 
                                            onClick={() => dialogDelete('wallets', key)}
                                        >
                                            <DeleteForever />
                                        </IconButton>

                                        {(wallet_name ? wallet_name?.name[i18n.language] : wallet_name?.name?.cn) + ': ' + data + '%'}
                                    </Box>
                                )
                            })
                        }
                        <Box>
                            <IconButton color="primary" onClick={() => handleDialogOpen('wallets', 'none', 'select', '')}><Add /></IconButton>
                        </Box>
                        {
                            inputErrors && inputErrors.wallet ?
                                <Typography variant="body2" style={{ color: "red" }}>{inputErrors.wallet}</Typography>
                                : null
                        }
                    </Box>
                </Grid>

            </Grid>

            <Dialog onClose={handleDialogClose} open={dialog.open} fullWidth>
                <DialogTitle onClose={handleDialogClose}>
                    {dialog.label}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <TextField
                                label={dialog.label}
                                variant="outlined"
                                fullWidth
                                select
                                value={dialog?.id}
                                onChange={({ target }) => setDialog({ ...dialog, id: target.value })}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                                <option key='' value='select' disabled>
                                    {t('general.pleaseSelect')}
                                </option>
                                {
                                    dialog.data.map((value, key) => (
                                        <option key={key} value={value.id}>
                                            {value.name ? value.name[i18n.language] : value.name.cn}
                                        </option>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField 
                                label={dialog.type === 'limits' ? t('bonus.amount') : t('bonus.percent')} 
                                variant="outlined" 
                                fullWidth
                                value={dialog.type === 'limits' ? dialog.value : dialog?.percent}
                                onChange={({ target }) => {
                                    if (dialog.type === 'limits') { setDialog({ ...dialog, value: target.value }) } 
                                    else { setDialog({ ...dialog, percent: target.value }) }
                                } }
                            />
                            <Typography variant="body2" style={{color: "#808080"}}>{dialog.percentNote}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="contained" onClick={dialogAddEdit} color="primary">
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/members`} component={RouterLink}>
                    <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData}>{t('button.save')}</Button>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default TradeSystem;
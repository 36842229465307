import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

export default function Index() {
    const [searchData, setSearchData] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const { t, i18n } = useTranslation();
    const [state, setState] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const { addAlert } = useNotificationLoading();
    const theme = useTheme();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl('/setting', apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setRowCount(response.data.listing.total);
                setState(response.data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `setting-edit`) ?
                    <Link underline='none' to={`/setting-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit style={{ color: theme.palette.button.main }} />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50,
            hideExport: true,
        },
        {
            header: `${t('setting.settingType')}`,
            accessorKey: 'type',
        },
        {
            header: `${t('setting.settingCode')}`,
            accessorKey: 'code',
        },
        {
            header: `${t('setting.settingValue')}`,
            accessorKey: 'value',
            Cell: ({ cell }) => (
                <span>{JSON.stringify(cell.row.original.value)}</span>
            ),
            enableSorting: false, // Disable sorting for this column
        },
        {
            header: `${t('setting.description')}`,
            accessorKey: 'description',
            enableSorting: false, // Disable sorting for this column
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
            minSize: 200
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);
    return (
        <div>
            <div style={{ paddingBottom: 5 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t('title.setting')}</b></Typography>
                        {
                            _.includes(permissions, "setting-create") &&
                            <Link underline='none' to={`/setting-add`} component={RouterLink}>
                                <IconButton style={{ color: theme.palette.button.main }}>
                                    <AddBoxOutlined />
                                </IconButton>
                            </Link>
                        }
                    </div>
                    <div>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Typography >{t('title.setting')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
            </div>
            <PaginationMaterialReactTable
                columns={columns}
                data={state}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'SettingList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </div>
    );
}
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiArrowLeft } from "react-icons/fi";

const BackToPrevious = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();

    const handleOnClick = () => {
        window.history.back();
    }

    return (
        <div className={classes.flexMiddle} style={{ paddingBottom: 30 }}>
            <Link underline="none" onClick={handleOnClick} style={{ cursor: 'pointer' }}>
                <div className={classes.flexMiddle}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backToPrevious')}</Typography>
                </div>
            </Link>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default BackToPrevious;
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography, Link } from '@mui/material';

import { getUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const MODULE = 'trades';

const TradeSystemStatus = (props) => {
    let { callApi } = props;
    const [tradeSystem, setTradeSystem] = useState({});
    const [statusList, setStatusList] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        action: '',
    });

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        setLoading(true);
        getUrl(`/get-trade-system`).then(response => {
            setLoading(false);
            if (response.status) {
                setStatusList(response.status_list);
                setTradeSystem({
                    ...response.trade_system?.value,
                    status: parseInt(response.trade_system?.value?.status),
                });
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const handleDialogOpen = (action) => {
        setConfirmDialog({
            open: true,
            action: action,
        });
    }

    const handleDialogClose = () => {
        setConfirmDialog({
            open: false,
            action: '',
        });
    }

    const actionButton = () => {
        // 10: { en: 'Active', cn: '活跃' }
        // 20: { en: 'Pending Split', cn: '待拆分' }
        // 30: { en: 'Split', cn: '已拆分' }
        // 40: { en: 'Pre-Selling', cn: '预售中' }
        // 50: { en: 'Stopped', cn: '暂停'}
        let text = '';
        switch (tradeSystem.status) {
            case 10: text = 'toPendingSplit'; break;
            case 20: text = 'toSplit'; break;
            case 30: text = 'toActive'; break;
            default: ;
        }
        return text ? <Button variant="contained" color="primary" size="small" disabled={text === 'developing' ? true : false} onClick={() => handleDialogOpen(text)}>{t('trades.' + text)}</Button> : null;
    }

    const submit = () => {
        setLoading(true);
        setConfirmDialog({ ...confirmDialog, open: false });
        let url = '';
        switch (confirmDialog.action) {
            case 'toPendingSplit':
                url = `/active-to-pendingsplit`; break;
            case 'toSplit':
                url = `/pendingsplit-to-split`; break;
            case 'toActive':
                url = `/split-to-active`; break;
            case 'stopTrading':
                // emergency stop
                putUrl(`/stop-trading`).then(response => {
                    setLoading(false);
                    if (response.status) {
                        setTradeSystem({ ...tradeSystem, status: parseInt(response.trade_system_status) });
                        addAlert('', t('trades.stopTrading'), 'success', '');
                    } else {
                        addAlert('', t('error.createError'), 'error', '');
                    }
                }).catch(error => {
                    setLoading(false);
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                });
                break;
            default:
                setLoading(false);
                addAlert('', t('trades.invalidSubmitAction'), 'error', '');
        }
        if (url) {
            handleDialogClose();
            putUrl(url).then(response => {
                setLoading(false);
                if (response.status) {
                    setTradeSystem({ ...tradeSystem, status: parseInt(response.trade_system_status) });
                    callApi();
                    addAlert('', response.message, 'success', '');
                } else {
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1">{t('trades.tradeSystemStatus') + ': '}</Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold", marginLeft: 10 }}>{statusList[tradeSystem.status] ?
                        (statusList[tradeSystem.status]?.[i18n.language] ? statusList[tradeSystem.status]?.[i18n.language] : statusList[tradeSystem.status]?.en) : ''}</Typography>
                    <div style={{ marginLeft: 10 }}>{actionButton()}</div>
                </div>
                {
                    tradeSystem.status !== 50 // Stopped
                        ?
                        <div>
                            <Button variant="contained" color="primary" size="small" onClick={() => handleDialogOpen('stopTrading')}>{t('button.stop')}</Button>
                        </div>
                        :
                        null
                }
            </div>
            <Dialog open={confirmDialog.open} onClose={handleDialogClose}>
                <DialogContent>
                    {
                        confirmDialog.action
                            ?
                            <Typography variant="h6">{t('trades.dialogContent.' + confirmDialog.action)}</Typography>
                            :
                            null
                    }
                    {
                        confirmDialog.action === 'toSplit'
                            ?
                            <Box style={{ marginTop: 10 }}>
                                <Typography variant="body2">
                                    {t('trades.toSplitContent', { splitRatio: tradeSystem.split_ratio })}
                                    <Link to={`/edit-trade-system`} component={RouterLink}>
                                        {t('title.tradeSystem')}
                                    </Link>
                                </Typography>
                                <Typography variant="body2">{t('trades.toSplitContent2')}</Typography>
                                <Link to={`/trade-add2`} component={RouterLink}>
                                    {t('button.bulkAdd')}
                                </Link>
                            </Box>
                            :
                            null
                    }
                    {/* {
                        confirmDialog.action === 'toPreSell'
                            ?
                            <Box style={{ marginTop: 10 }}>
                                <Typography variant="body2">{t('trades.toPreSellContent')}</Typography>
                                <Link to={`/trade-add2`} component={RouterLink}>
                                    {t('button.bulkAdd')}
                                </Link>
                            </Box>
                            :
                            null
                    } */}
                </DialogContent>
                <DialogActions style={{ padding: 20 }}>
                    <Button variant="outlined" onClick={handleDialogClose} style={{ marginRight: 5 }}>
                        <Typography variant="button">{t('button.cancel')}</Typography>
                    </Button>
                    <Button variant="contained">
                        <Typography onClick={submit} variant="button" style={{ color: '#fff' }}>{t('button.confirm')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TradeSystemStatus
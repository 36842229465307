import React, { useState , useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams , useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link,Box, Breadcrumbs, Grid, Typography, Tabs, Tab } from '@mui/material';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';
import BackToPrevious from '@layouts/BackToPrevious';

import BasicInfo from './BasicInfo';
import EditAddress from './EditAddress';
import PasswordInfo from './PasswordInfo';
import RolePermission from './RolePermission';
import SecurityPassword from './SecurityPassword';

const MODULE = 'user';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Edit = () => {
    let { id } = useParams();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(0);
    const [ state , setState] = useState({});
    const [ option , setOption]  = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setLoading(true);

        getUrl(`/admin-options`).then(response => {
            setLoading(false);
            if (response.status) {
                setOption(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [addAlert, id])

    const callApi = () => {
        setLoading(true);

        getUrl(`/admins/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setState(response.data);
            } else {
                addAlert("", response?.message || t('error.contactSupport'), 'error', '');
                navigate("/dashboard");
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    };

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.adminEdit`)}</b></Typography>
                <div>
                <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/admins`}>
                            {t(`title.admin`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.adminEdit`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label={t(`${MODULE}.basicInfo`)} {...a11yProps(0)} value={0} />
                    <Tab label={t(`${MODULE}.editAddress`)} {...a11yProps(1)} value={1} />
                    <Tab label={t(`${MODULE}.passwordInfo`)} {...a11yProps(2)} value={2} />
                    <Tab label={t(`${MODULE}.securityPassword`)} {...a11yProps(3)} value={3} />
                    <Tab label={t(`${MODULE}.rolePermission`)} {...a11yProps(4)} value={4} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <BasicInfo userInfo={state} statusList={option?.statusList || []} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <EditAddress addressInfo={state} callApi={callApi} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <PasswordInfo />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <SecurityPassword />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
                <RolePermission  roleList ={option?.roleList || []} permission={option?.allPermissions || []} rolePermissions={option?.permissionByRoles || []} userInfo={state} />
            </TabPanel>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default Edit;
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

//ICON
import { AddBoxOutlined, Edit } from '@mui/icons-material';

const MODULE = 'user';

const Admin = () => {
    const [data, setData] = useState([]);
    const [ option , setOption]  = useState({});
    const [tableLoading, setTableLoading] = useState(false);
    const [searchData, setSearchData] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [statusList, setStatusList] = useState([]);
    const [rankList, setRankList] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const language = i18n.language;

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/admins` , apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setRowCount(response.data.listing.total);
                setData(response.data.listing.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        setTableLoading(true);

        getUrl(`/admin-options`).then(response => {
            setTableLoading(false);
            if (response.status) {
                setOption(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let newStatusList = [];
        _.map(option?.statusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key.toString() });
        })
        setStatusList(newStatusList);

        let newRankList = [];
        _.map(option?.rankList, (rank, key) => {
            let textDisplay = rank.name[i18n.language] ? rank.name[i18n.language] : rank.name.en;
            newRankList.push({ text: textDisplay, value: key.toString() });
        })        
        setRankList(newRankList);
        // eslint-disable-next-line
    }, [option?.statusList, option?.rankList, i18n.language]);
    
    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `admin-edit`) ?
                    <Link underline='none' to={`/admin-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit style={{ color: theme.palette.button.main }} />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50,
            hideExport: true,
        },
        {
            header: `${t(`${MODULE}.username`)}`,
            accessorKey: 'username',
        },
        {
            header: `${t(`${MODULE}.name`)}`,
            accessorKey: 'name',
        },
        {
            header: `${t(`${MODULE}.email`)}`,
            accessorKey: 'email',
        },
        {
            header: `${t(`${MODULE}.mobile`)}`,
            accessorKey: 'mobile',
            Cell: ({ cell }) => (
                <Typography>{cell?.row?.original?.mobile || '-'}</Typography>
            )
        },
        {
            id: 'rank.name.' + language,
            accessorFn: (row) => (row?.rank?.name?.[i18n.language] ? row?.rank?.name?.[i18n.language] : '-'),
            header: `${t(`${MODULE}.rank`)}`,
            filterFn: 'equals',
            filterSelectOptions: option?.rankList?.map(rank => ({ text: rank.name[i18n.language], value: rank.id.toString() })) || [],
            filterVariant: 'select',
            enableSorting: false,
            Cell: ({ cell }) => (
                // Update the logic to display the name property of rank
                cell?.row?.original?.rank?.name?.[i18n.language]   
                    ? <>{cell.row?.original?.rank?.name[i18n.language]}</>
                    : <>-</>
            ),
        },
        {
            id: 'crank.name.' + language,
            accessorFn: (row) => (row?.crank?.name?.[i18n.language] ? row?.crank?.name?.[i18n.language] : '-'),
            header: `${t(`${MODULE}.crank`)}`,
            filterFn: 'equals',
            filterSelectOptions: option?.rankList?.map(rank => ({ text: rank.name[i18n.language], value: rank.id.toString() })) || [],
            filterVariant: 'select',
            enableSorting: false,
            Cell: ({ cell }) => (
                cell?.row?.original?.crank?.name?.[i18n.language]   // Check if the nested key exists
                    ? <>{cell.row?.original?.crank?.name[i18n.language]}</>
                    : <>-</>
            ),
        },
        {
            accessorKey: 'status',
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{t(`${MODULE}.statusInfo.${cell.row.original.status}`)}</>
            ),
        },
        {
            header: `${t(`general.createdAt`)}`,
            accessorKey: 'created_at',
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t ,statusList, option?.rankList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.admin`)}</b></Typography>
                    {
                        _.includes(permissions, "admin-create") &&
                        <Link underline='none' to={`/admin-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                    }
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.admin`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'AdminList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                // columnVisibility={{
                //     ['rank.' + i18n.language]: false,
                //     ['crank.' + i18n.language]: false
                // }}
            />
        </>
    )
}

export default Admin;
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const INITIAL_STATE = {
    username: '',
    profile_action: '',
    active_unit: '',
    passive_unit: '',
    lock_value: '',
};

const ProfileAdjustment = () => {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);

    const action = [
        { code: "add", label: t(`wallets.add`) }, 
        { code: "deduct", label: t(`wallets.deduct`) }
    ];

    const handleChange = ({ target }) => {
        let { name, value } = target;

        if (name === 'active_unit' || name === 'passive_unit' || name === 'lock_value') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }

        setState({ ...state, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let updateData = {
            username: state.username,
            profile_action: state.profile_action,
            active_unit: state.active_unit,
            passive_unit: state.passive_unit,
            lock_value: state.lock_value,
        }
        postUrl(`/profile-adjustment`, updateData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                setState(INITIAL_STATE);
                addAlert('', t('success.createSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', _.size(errors) > 0 ? t('error.createError') : response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.profileAdjustment`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/trade-profile`}>
                            {t(`title.tradeProfile`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.profileAdjustment`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.username`)}
                            variant="outlined"
                            value={state?.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.action`)}
                            variant="outlined"
                            value={state?.profile_action}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profile_action ? inputErrors.profile_action : ''}
                            error={inputErrors && inputErrors.profile_action ? true : false}
                            name="profile_action"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleChange}
                        >
                            <option key='' value='' disabled>{t('general.pleaseSelect')}</option>
                            {_.map(action, (value, key) => (
                                <option key={key} value={value.code}>
                                    {value.label}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`tradeProfile.activeUnit`)}
                            variant="outlined"
                            value={state?.active_unit}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.active_unit ? inputErrors.active_unit : ''}
                            error={inputErrors && inputErrors.active_unit ? true : false}
                            name="active_unit"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`tradeProfile.passiveUnit`)}
                            variant="outlined"
                            value={state?.passive_unit}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.passive_unit ? inputErrors.passive_unit : ''}
                            error={inputErrors && inputErrors.passive_unit ? true : false}
                            name="passive_unit"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`tradeProfile.lockValue`)}
                            variant="outlined"
                            value={state?.lock_value}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.lock_value ? inputErrors.lock_value : ''}
                            error={inputErrors && inputErrors.lock_value ? true : false}
                            name="lock_value"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.confirm')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default ProfileAdjustment
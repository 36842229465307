import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl, getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const INITIAL_STATE = {
    username: '',
    merchant: '',
    redeemable_action: '',
    redeemable_value: '',
};

const RedeemableAdjustment = () => {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [merchants, setMerchants] = useState(null);

    const action = [
        { code: "add", label: t(`wallets.add`) }, 
        { code: "deduct", label: t(`wallets.deduct`) }
    ];

    const maction = [
        { id: 0, code: "system", label: t(`merchant.system`) },
    ];

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, []);

    const callApi = () => {
        setLoading(true);
        getUrl(`/admin/active-merchants`).then(response => {
            setLoading(false);
            if (response.status) {
                setMerchants(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;

        if (name === 'redeemable_value') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }

        setState({ ...state, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let updateData = {
            username: state.username,
            merchant: state.merchant,
            redeemable_action: state.redeemable_action,
            redeemable_value: state.redeemable_value,
        }
        postUrl(`/redeemable-adjustment`, updateData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                setState(INITIAL_STATE);
                addAlert('', t('success.createSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', errors ? t('error.createError') : response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    if (_.size(merchants) > 0) {
        _.map(merchants, merchant => (
            maction.push({id: merchant?.id, code: merchant?.code, label: merchant?.name?.[i18n.language]})
        ))
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.redeemableAdjustment`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/trade-redeemable`}>
                            {t(`title.tradeRedeemable`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.redeemableAdjustment`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.username`)}
                            variant="outlined"
                            value={state?.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            name="username"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.action`)}
                            variant="outlined"
                            value={state?.redeemable_action}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.redeemable_action ? inputErrors.redeemable_action : ''}
                            error={inputErrors && inputErrors.redeemable_action ? true : false}
                            name="redeemable_action"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleChange}
                        >
                            <option key='' value='' disabled>{t('general.pleaseSelect')}</option>
                            {_.map(action, (value, key) => (
                                <option key={key} value={value.code}>
                                    {value.label}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.name`)}
                            variant="outlined"
                            value={state?.merchant}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.merchant ? inputErrors.merchant : ''}
                            error={inputErrors && inputErrors.merchant ? true : false}
                            name="merchant"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleChange}
                        >
                            <option key='' value='' disabled>{t('general.pleaseSelect')}</option>
                            {_.map(maction, (value, key) => (
                                <option key={key} value={value.id}>
                                    {_.isArray(value?.label) ? value?.label?.[i18n.language] : value?.label}{` (${value.code})`}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`tradeRedeemable.redeemableValue`)}
                            variant="outlined"
                            value={state?.redeemable_value}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.redeemable_value ? inputErrors.redeemable_value : ''}
                            error={inputErrors && inputErrors.redeemable_value ? true : false}
                            name="redeemable_value"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.confirm')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default RedeemableAdjustment
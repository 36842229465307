import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import _, { isNaN, isNull } from 'lodash';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography, IconButton, List, ListItem, ListItemText, Dialog, DialogContent } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl, getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { Calculate, Search } from '@mui/icons-material';

const INITIAL_STATE = { merchant_id: '', search_merchant: '', search_username: '', user_id: '', redeemable_value: '', unit: '', estimate_system_value: 0, estimate_merchant_value: 0, estimate_passive_unit: 0, estimate_active_unit: 0, current_price: 0, action: 'add' }

const MerchantTransactionAdjustment = () => {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [merchant, setMerchant] = useState([]);
    const [member, setMember] = useState([]);
    const [memberInfo, setMemberInfo] = useState([]);
    const [rateAdjust, setRateAdjust] = useState(false);

    const action = [
        { code: "add", label: t(`wallets.add`) },
        // { code: "deduct", label: t(`wallets.deduct`) }
    ];

    const handleSearchFunction = ($type) => {
        let params = {}
        setInputErrors([]);
        if ($type === 'merchant') {
            if (isNull(state?.search_merchant)) {
                addAlert("", t('error.fieldRequired', { secret: t('merchant.code') }), 'error', '');
            } else {
                setState(prevState => ({ ...prevState, merchant_id: '' , search_username:'' , user_id:'' }))
                params['search_merchant'] = state?.search_merchant;
            }
        } else if ($type === "member") {
            if (isNull(state?.search_username)) {
                addAlert("", t('error.fieldRequired', { secret: t('user.username') }), 'error', '');
            } else {
                setState(prevState => ({ ...prevState, user_id: '' }));
                params['search_username'] = state?.search_username;
                if (!isNull(state?.search_merchant) && state?.search_merchant !== '') {
                    params['search_merchant'] = state?.search_merchant;
                }
            }
        }
        setLoading(true);

        getUrl(`/spend-adjustment-option`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setMerchant(response?.data?.merchant);
                setMember(response?.data?.member);
                setState(prevState => ({ ...prevState, current_price: response?.data?.current_price }))
            }
            else {
                addAlert("", response.message, 'error', '');
                setInputErrors(response.errors);
                setMerchant([]);
                setMember([]);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;

        switch (name) {
            case 'redeemable_value':
                // Remove all non-numeric characters except the decimal point
                value = value.replace(/[^0-9.]/g, '');

                // Ensure only one decimal point is present
                const decimalCount = value.split('.').length - 1;
                if (decimalCount > 1) {
                    value = value.slice(0, value.lastIndexOf('.'));
                }

                // Limit decimal places to two
                const parts = value.split('.');
                if (parts[1] && parts[1].length > 2) {
                    value = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
                setState({ ...state, [name]: value });
                break;
            case 'user_id':
                setState({ ...state, [name]: value });
                getUserTradeInfo(value , state?.merchant_id);
                break;
            default:
                setState({ ...state, [name]: value });
                break;
        }
    }

    const getUserTradeInfo = (user , merchant) => {
        let params = {
            'user_id': user,
            'merchant_id' : merchant 
        }
        setInputErrors([]);

        getUrl(`/spend-adjustment-option`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setMemberInfo(response?.data?.member_info);
            }
            else {
                addAlert("", response.message, 'error', '');
                setInputErrors(response.errors);
                setMerchant({});
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const estimatePayment = () => {
        if (state?.redeemable_value > 0) {
            if (state?.redeemable_value <= parseFloat(memberInfo?.redeemable) + parseFloat(memberInfo?.system_redeemable)) {
                //estimate redeemable value
                let left = _.clone(state?.redeemable_value);
                let usedRedeemable = 0;
                let systemRedeemable = 0;
                if (memberInfo?.redeemable > 0) {
                    usedRedeemable = Math.min(left, memberInfo?.redeemable);
                    left = left - usedRedeemable;
                }

                if (left > 0 && memberInfo?.system_redeemable > 0) {
                    systemRedeemable = Math.min(left, memberInfo?.system_redeemable);
                    left = left - memberInfo?.system_redeemable;
                }

                //estimate unit
                let sumUnit = Math.ceil(_.clone(state?.redeemable_value) / state?.current_price);
                let leftUnit = sumUnit;
                let usedPassive = 0;
                let usedActive = 0
                if (memberInfo?.sum_passive > 0) {
                    usedPassive = Math.min(leftUnit, memberInfo?.sum_passive);
                    leftUnit = leftUnit - usedPassive;
                }

                if (leftUnit > 0 && memberInfo?.sum_active > 0) {
                    usedActive = Math.min(leftUnit, memberInfo?.sum_active);
                    leftUnit = leftUnit - usedActive;
                }

                if (leftUnit == 0) {
                    setState(prevState => ({
                        ...prevState,
                        estimate_merchant_value: usedRedeemable,
                        estimate_system_value: systemRedeemable,
                        estimate_passive_unit: usedPassive,
                        estimate_active_unit: usedActive,
                        unit: sumUnit,
                    }));
                } else {
                    addAlert('', t('error.unitInsufficientBalance'), 'error', '');
                }
            } else {
                addAlert('', t('error.valueInsufficientBalance'), 'error', '');
            }
        } else {
            addAlert('', t('error.requiredFillAllSection'), 'error', '');
        }
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        if (state?.unit > 0 && state?.redeemable_value > 0) {
            let updateData = {
                merchant_id: state?.merchant_id,
                user_id: state?.user_id,
                action: state?.action,
                redeemable_value: state?.redeemable_value,
                current_price: state?.current_price
            }
            postUrl(`/trade-spend-adjustment`, updateData).then(response => {
                setLoading(false);
                const { status, data, errors, message } = response;
                if (status) {
                    setState(INITIAL_STATE);
                    setRateAdjust(false);
                    setMerchant([]);
                    setMember([]);
                    addAlert('', t('success.createSuccess'), 'success', '');
                } else {

                    if (errors?.current_price) {
                        setState(prevState => ({
                            ...prevState,
                            current_price: data?.current_price,
                            old_current_price: state?.current_price,
                        }));
                        estimatePayment();
                        setRateAdjust(true);
                    } else {
                        addAlert('', message || t('error.contactSupport'), 'error', '');
                        setInputErrors(errors);
                    }
                    setInputErrors(errors);
                    addAlert('', !errors ? t('error.createError') : response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setLoading(false);
            addAlert('', t('error.calculateSpend'), 'error', '');
        }

    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.merchantTransactionAdd`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/merchant-transaction`}>
                            {t(`title.reportMerchantTransaction`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.merchantTransactionAdd`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }}>
                        <TextField
                            label={t(`wallets.searchMerchant`)}
                            variant="outlined"
                            value={state?.search_merchant}
                            onChange={handleChange}
                            name="search_merchant"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.search_merchant ? inputErrors.search_merchant : t(`wallets.searchMerchantHelperMsg`)}
                            error={inputErrors && inputErrors.search_merchant ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => handleSearchFunction("merchant")} >
                                        <Search />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`merchant.name`)}
                            variant="outlined"
                            value={state?.merchant_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.merchant_id ? inputErrors.merchant_id : ''}
                            error={inputErrors && inputErrors.merchant_id ? true : false}
                            name="merchant_id"
                            onChange={handleChange}
                            select
                            SelectProps={{
                                native: true,
                            }}
                            disabled={_.size(merchant) > 0 ? false : true}
                        >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {
                                _.size(merchant) > 0 &&
                                _.map(merchant, (value, key) => {
                                    return (
                                        <option key={key} value={value.id}>
                                            {value.name[i18n.language] ? value.name[i18n.language] : value.name.en}
                                        </option>
                                    )
                                })
                            }
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }}>
                        <TextField
                            label={t(`genealogy.searchUsername`)}
                            variant="outlined"
                            value={state?.search_username}
                            onChange={handleChange}
                            name="search_username"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.search_username ? inputErrors.search_username : ''}
                            error={inputErrors && inputErrors.search_username ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => handleSearchFunction("member")} >
                                        <Search />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`user.username`)}
                            variant="outlined"
                            value={state?.user_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.user_id ? inputErrors.user_id : ''}
                            error={inputErrors && inputErrors.user_id ? true : false}
                            name="user_id"
                            onChange={handleChange}
                            select
                            SelectProps={{
                                native: true,
                            }}
                            disabled={_.size(member) > 0 ? false : true}
                        >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {
                                _.size(member) > 0 &&
                                _.map(member, (value, key) => {
                                    return (
                                        <option key={key} value={value.id}>
                                            {value?.username}
                                        </option>
                                    )
                                })
                            }
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`wallets.action`)}
                            variant="outlined"
                            value={state?.action}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.action ? inputErrors.action : ''}
                            error={inputErrors && inputErrors.action ? true : false}
                            name="action"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleChange}
                        >
                            <option key='' value='' disabled>{t('general.pleaseSelect')}</option>
                            {_.map(action, (value, key) => (
                                <option key={key} value={value.code}>
                                    {value.label}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`tradeRedeemable.redeemableValue`)}
                            variant="outlined"
                            value={state?.redeemable_value}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.redeemable_value ? inputErrors.redeemable_value : ''}
                            error={inputErrors && inputErrors.redeemable_value ? true : false}
                            name="redeemable_value"
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={estimatePayment} >
                                        <Calculate />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                <Grid container spacing={3} justifyContent={'stretch'} alignItems={'stretch'} style={{ flex: 1 }}>
                    <Grid item xs={12} sm={6}>
                        <div style={{ border: `3px solid ${theme.palette.primary.main}`, borderRadius: 5, padding: 20, height: '100%' }}>
                            <Typography variant='h6' style={{ fontWeight: 'bold' }}>{t('trades.tradeInfo')}</Typography>
                            <List>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {memberInfo?.system_redeemable || 0}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.systemRedeemableValue')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {memberInfo?.redeemable || 0}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('tradeRedeemable.redeemableValue')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {memberInfo?.sum_passive || 0}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.passive_unit')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {memberInfo?.sum_active || 0}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.active_unit')} />
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={{ border: `3px solid ${theme.palette.primary.main}`, borderRadius: 5, padding: 20, height: '100%' }}>
                            <Typography variant='h6' style={{ fontWeight: 'bold' }}>{t('trades.spendPreview')}</Typography>
                            <List>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {state?.current_price}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.rate')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {isNaN(state?.redeemable_value) || state?.redeemable_value <= 0 ? 0 : parseFloat(state?.redeemable_value).toFixed(2)}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.approximatelyTotalValue')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {isNaN(state?.estimate_system_value) || state?.estimate_system_value <= 0 ? 0 : parseFloat(state?.estimate_system_value).toFixed(3)}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.systemRedeemableValue')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {isNaN(state?.estimate_merchant_value) || state?.estimate_merchant_value <= 0 ? 0 : parseFloat(state?.estimate_merchant_value).toFixed(3)}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('tradeRedeemable.redeemableValue')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {`≈ ${state?.estimate_passive_unit || 0}`}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.passive_unit')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {`≈ ${state?.estimate_active_unit || 0}`}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.active_unit')} />
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={submitData}>{t('button.confirm')}</Button>
            </Box>

            <Dialog open={rateAdjust} onClose={() => setRateAdjust(!rateAdjust)}>
                <DialogContent style={{ padding:40}}>
                    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12}>
                            <Typography variant='h6' style={{ textAlign: 'center' }}>{t('trades.rateAdjustment')}</Typography>
                            <Typography variant='subtitle2' style={{ textAlign: 'center' }}>{t('trades.rateAdjustmentDesc')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{  border: `3px solid ${theme.palette.primary.main}`, borderRadius: 5, padding: 20 , display:'flex' , alignContent:'center' , justifyContent:'center', gap:10 }}>
                                <div>
                                    <Typography variant="h6" >{state?.old_current_price}</Typography>
                                </div>
                                <div>
                                    <Typography variant='h6' >{t('trades.to')}</Typography>
                                </div>
                                <div><Typography variant="h6" style={{ fontWeight: 'bold' }}>{state?.current_price}</Typography></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} container className='flex-l-m' style={{ marginTop: 10 }}>
                            <Typography style={{ fontWeight: 'bold' }}>{t('trades.spendPreview')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {isNaN(state?.redeemable_value) || state?.redeemable_value <= 0 ? 0 : parseFloat(state?.redeemable_value).toFixed(2)}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.approximatelyTotalValue')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {isNaN(state?.estimate_system_value) || state?.estimate_system_value <= 0 ? 0 : parseFloat(state?.estimate_system_value).toFixed(3)}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.systemRedeemableValue')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {isNaN(state?.estimate_merchant_value) || state?.estimate_merchant_value <= 0 ? 0 : parseFloat(state?.estimate_merchant_value).toFixed(3)}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('tradeRedeemable.redeemableValue')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {`≈ ${state?.estimate_passive_unit || 0}`}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.passive_unit')} />
                                </ListItem>
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Typography variant='subtitle1'>
                                            {`≈ ${state?.estimate_active_unit || 0}`}
                                        </Typography>
                                    }
                                >
                                    <ListItemText primary={t('trades.active_unit')} />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid container item xs={12} className='flex-c-m' >
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={()=>setRateAdjust(!rateAdjust)}
                                >
                                    {t('button.cancel')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={submitData}
                                >
                                    {t('button.confirm')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))


export default MerchantTransactionAdjustment;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, TextField, Typography, Grid } from '@mui/material';
import _ from 'lodash';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { storeProfile } from '@actions';
import { QRCodeSVG } from 'qrcode.react';

const TwoFactorAuth = () => {
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [state, setState] = useState({
        secret: '',
        authentication_code: ''
    });
    const [enableAuth, setEnableAuth] = useState(false);
    const [disableAuth, setDisableAuth] = useState(false);
    const { two_factor_confirmed_at } = useSelector(state => state.user);
    const [errorFormState, setErrorFormState] = useState({
        authentication_code: ''
    })
    const [qrcode, setQrcode] = useState([]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        setState({ ...state, [name]: newValue });
    }

    const getSecret = () => {
        setLoading(true);
        setErrorFormState({});
        let params = {
            type: 'enable',
        }
        postUrl(`authenticator`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, secret: response.secret });
                setQrcode(response.url);
                setEnableAuth(true);
            } else {
                addAlert('', JSON.stringify(response.message), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    const verifyCode = () => {
        setLoading(true);
        setErrorFormState("");
        let params = {
            type: 'confirm',
            authentication_code: state.authentication_code,
        }
        postUrl(`authenticator`, params).then(response => {
            setState({ ...state, authentication_code: "" });
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.profile));
                addAlert('', t('success.enable2FASuccess'), 'success', '');
            } else {
                if (response.errors) {
                    let allErrorField = [];
                    _.map(response.errors, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem;
                    })
                    setErrorFormState(allErrorField);
                    addAlert('', JSON.stringify(_.map(response.errors).join(' ')), 'error', '');
                } else {
                    addAlert(t('error.enable2FAError'), response.message, 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    const disableTwoFactorAuth = () => {
        setLoading(true);
        let params = {
            type: 'disable',
            authentication_code: state.authentication_code,
        }
        postUrl(`authenticator`, params).then(response => {
            setState({ ...state, authentication_code: "" });
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.profile));
                setDisableAuth(false);
                setEnableAuth(false);
                addAlert('', t('success.disable2FASuccess'), 'success', '');
            } else {
                if (response.errors) {
                    let allErrorField = [];
                    _.map(response.errors, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem;
                    })
                    setErrorFormState(allErrorField);
                    addAlert('', JSON.stringify(_.map(response.errors).join(' ')), 'error', '');
                } else {
                    addAlert(t('error.disable2FAError'), response.message, 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    return (
        <Box>
            <Box style={{ padding: '0 20px' }}>
                <Typography variant="h6">
                    {t('user.2FA')}
                </Typography>
                <Typography style={{ fontSize: 15 }} color="textSecondary">
                    {
                        two_factor_confirmed_at === 1 ?
                            t('user.enabled2FA')
                            : t('user.2FASubtitle')
                    }
                </Typography>
            </Box>
            {
                !two_factor_confirmed_at && !enableAuth ?
                    <div style={{ padding: '30px 20px 5px' }}>
                        <Typography variant="h6">{t('user.enable2FA')}</Typography>
                        <div>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                                <Button variant="contained" onClick={getSecret} style={{ width: 500, maxWidth: '80%', color: '#fff' }} color="primary">{t('button.enable')}</Button>
                            </Box>
                        </div>
                    </div>
                    : null
            }
            {
                two_factor_confirmed_at && !disableAuth ?
                    <div style={{ padding: '30px 20px 5px' }}>
                        <Typography variant="h6">{t('user.disable2FA')}</Typography>
                        <div>
                            <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 30 }}>
                                <Button variant="contained" onClick={() => setDisableAuth(true)} style={{ width: 500, maxWidth: '80%' }} color="secondary">{t('button.disable')}</Button>
                            </Box>
                        </div>
                    </div>
                    : null
            }
            {
                // disable 2fa
                two_factor_confirmed_at && disableAuth ?
                    <div style={{ padding: '30px 20px 5px' }}>
                        <Typography variant="h6">{t('user.disable2FA')}</Typography>
                        <Typography color="textSecondary" >{t('user.disable2FACaption')}</Typography>
                        <div style={{ padding: '30px 20px 5px' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '10px 0' }}>
                                <TextField
                                    error={errorFormState.authentication_code ? true : false}
                                    helperText={errorFormState.authentication_code}
                                    label={t('user.authenticationCode')}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={state.authentication_code}
                                    inputProps={{ name: 'authentication_code' }}
                                    onChange={handleChange}
                                />
                                <Button variant="contained" onClick={disableTwoFactorAuth} style={{ width: 100, maxWidth: '50%', color: '#fff' }} color="primary">{t('button.submit')}</Button>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                // enable 2fa
                !two_factor_confirmed_at && enableAuth ?
                    <Grid container style={{ padding: '30px 20px 30px' }} flexDirection="row" spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h6" >{t('user.scan2FAQrcode')}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
                                <QRCodeSVG value={qrcode} />
                            </div>
                            <Typography variant="h6" style={{ padding: '10px 0' }}>{t('user.insertSecret')}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
                                <Typography color="textSecondary" style={{ fontSize: 15 }}><b>{t('user.secretKey', { secret: state.secret })}</b></Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} style={{ marginTop: '60px' }}>
                            <Typography variant="h6" style={{ padding: '10px 0' }}>{t('user.verifyCode')}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: '10px 0' }}>
                                <TextField
                                    error={errorFormState.authentication_code ? true : false}
                                    helperText={errorFormState.authentication_code}
                                    label={t('user.authenticationCode')}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={state.authentication_code}
                                    inputProps={{ name: 'authentication_code' }}
                                    onChange={handleChange}
                                />
                                <Button variant="contained" onClick={verifyCode} style={{ width: 100, maxWidth: '50%', color: '#fff' }} color="primary">{t('button.submit')}</Button>
                            </div>
                        </Grid>
                    </Grid>
                    : null
            }
        </Box>
    )
}

export default TwoFactorAuth
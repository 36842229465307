import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import { AddBoxOutlined, Edit } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PaginationMaterialReactTable from '@layouts/PaginationMaterialReactTable';

const MODULE = 'notice';

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [optionList, setOptionList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [moduleList, setModuleList] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/notices`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleList(response.data.option);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newList = [];
        _.map(moduleList, (listing, key) => {
            let newSelectList =[];
            _.map(listing , (info ,  infoIndex)=>{
                let textDisplay = info[i18n.language] ? info[i18n.language] : info.en;
                newSelectList.push({ text: textDisplay, value: infoIndex.toString() });
            })
            newList[key] = newSelectList;
        })
        setOptionList(newList);
        // eslint-disable-next-line
    }, [moduleList, i18n.language]);

    const columns = useMemo(() => ([
        {
            header: `${t('table.actions')}`,
            accessorKey: 'actions',
            Cell: ({ cell }) => (
                _.includes(permissions, `notice-edit`) ?
                    <Link underline='none' to={`/notice-edit/${cell.row.original.id}`} component={RouterLink}>
                        <Edit style={{ color: theme.palette.button.main }} />
                    </Link>
                    : null
            ),
            enableSorting: false,
            enableColumnFilter: false,
            maxSize: 50,
            hideExport: true,
        },
        {
            // use for export, hide for display
            header: `${t(`${MODULE}.title`)}`,
            accessorKey: 'title.' + i18n.language,
        },
        {
            // use for display, hide for export
            accessorKey: 'title',
            header: `${t(`${MODULE}.title`)}`,
            Cell: ({ cell }) => (
                <>{cell.row.original.title[i18n.language] ? cell.row.original.title[i18n.language] : cell.row.original.title.cn}</>
            ),
            hideExport: true,
        },
        {
            accessorKey: 'type',
            header: `${t(`${MODULE}.type`)}`,
            filterFn: 'equals',
            filterSelectOptions: optionList['generalTypeList'],
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{t(`notice.type_${cell.row.original.type}`)}</>
            ),
        },
        {
            accessorKey: 'content_type',
            header: `${t(`${MODULE}.contentType`)}`,
            filterFn: 'equals',
            filterSelectOptions: optionList['contentTypeList'],
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{t(`notice.contentType_${cell.row.original.content_type}`)}</>
            ),
        },
        // {
        //     accessorKey: 'popup',
        //     header: `${t(`${MODULE}.popupDisplayType`)}`,
        // },
        // {
        //     accessorKey: 'popup_sdate',
        //     header: `${t(`${MODULE}.popupStartDate`)}`,
        //     filterVariant: 'date',
        //     filterFn: 'lessThan',
        //     sortingFn: 'date',
        //     accessorFn: (row) => new Date(row.popup_sdate),
        //     Cell: ({ cell }) => (
        //         <>{cell?.row?.original?.popup_sdate !== null ? moment(cell?.row?.original?.popup_sdate).format('YYYY-MM-DD') : " -"}</>
        //     ),
        // },
        // {
        //     accessorKey: 'popup_edate',
        //     header: `${t(`${MODULE}.popupEndDate`)}`,
        //     filterVariant: 'date',
        //     filterFn: 'lessThan',
        //     sortingFn: 'date',
        //     accessorFn: (row) => new Date(row.popup_edate),
        //     Cell: ({ cell }) => (
        //         <>{cell?.row?.original?.popup_sdate !== null ? moment(cell?.row?.original?.popup_edate).format('YYYY-MM-DD') : " -"}</>
        //     ),
        // },
        {
            accessorKey: 'status',
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: optionList['statusList'],
            filterVariant: 'select',
            Cell: ({ cell }) => (
                <>{t(`notice.status_${cell.row.original.status}`)}</>
            ),
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, optionList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.notice`)}</b></Typography>
                    {_.includes(permissions, `notice-create`) ?
                        <Link underline='none' to={`/notice-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.notice`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'NoticeList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                columnVisibility={{ ['title.' + i18n.language]: false }}
            />
        </>
    )
}

export default Listing
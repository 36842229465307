import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { 
    Box, 
    Breadcrumbs, 
    Button, 
    Grid, 
    Link, 
    TextField, 
    Typography, 
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '@utils/ApiAction';
// import { buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import BackToPrevious from '@layouts/BackToPrevious';

import { IoClose , IoAddOutline } from "react-icons/io5";

const MODULE = 'trades';

export default function Detail() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading, loading } = useNotificationLoading();
    // let navigate = useNavigate();

    const INITIAL_DATA = {
        price: '', 
        unit: '',
        max_unit: '',
    }
    const [trade, setTrade] = useState({});
    const [inputErrors, setInputErrors] = useState({});
    const [data, setData] = useState([]);

    const location = useLocation();
    const formType = location.pathname === `/trade-add` ? "add" : "edit";

    useEffect(() => {
        setLoading(true);
        callApi()

        // eslint-disable-next-line
    }, []);

    const callApi = () => {
        getUrl(`/get-all-trade-data`).then(response => {
            if (response.status) {
                setTrade(response.data)
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        })
        .catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        .finally(() => {
            setLoading(false);
        })
        ;
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors({});
        let postData = {
            trades: data,
        };

        postUrl(`/${MODULE}`, postData).then(response => {
            if (response.status) {
                setData([]);
                callApi()
                addAlert('', response.message || t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });

    }

    const addNewRow = () => {
        setData( prevData => [ ...prevData, INITIAL_DATA  ] );
    };

    const handleInputChange = ({ index, fieldName, value, type }) => {
        let setValue = null;
        
        switch (type) {
            case 'decimal':
                setValue = !(/^\s*$/.test(value)) && !isNaN(value) 
                ? 
                    value
                : 
                    ''
                ;
                break;

            case 'integer':
                setValue = !/^\s*$/.test(value) && !isNaN(value) && Number.isInteger(parseInt(value, 10))
                ?
                    parseInt(value)
                :
                    ''
                ;
                break;

            default:
                setValue = value;
                break;
        }
        if (fieldName === 'price'){
            setData( prevData => [
                ...prevData.slice(0, index),
                { ...prevData[index], [fieldName]: setValue , 'unit': '', 'max_unit':''},
                ...prevData.slice(index + 1),
                ]);
        }else{
            setData( prevData => [
                ...prevData.slice(0, index),
                { ...prevData[index], [fieldName]: setValue },
                ...prevData.slice(index + 1),
                ]);
        }
        
    };

    const removeRow = (index) => {
        setData( prevData => [
            ...prevData.slice(0, index),
            ...prevData.slice(index + 1),
        ]);
    }
    
    return (
        <>
            <BackToPrevious />

            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 30 }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Button 
                        size="large" 
                        variant="contained" 
                        style={{ backgroundColor: theme.palette.button.main }} 
                        className={classes.buttonStyle} 
                        onClick={addNewRow}
                    >
                        {t(`button.add`)} <IoAddOutline className={classes.iconStyle} />
                    </Button>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12}>
                    {data.map((rowData, index) => (
                        <Grid container spacing={3}  key={index}>
                            <Grid item xs={12} sm={6} md={3} >
                                <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        type="text"
                                        label={t(`${MODULE}.price`)}
                                        variant="outlined"
                                        className={classes.inputStyle}
                                        value={rowData.price}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors[`trades.${index}.price`] ? inputErrors[`trades.${index}.price`][0] : ''}
                                        error={inputErrors && inputErrors[`trades.${index}.price`] ? true : false}
                                        onChange={(e) => handleInputChange({index: index, fieldName: 'price', value: e.target.value, type: 'decimal' })}
                                        style={{
                                            margin: 10
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} >
                                <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        type="text"
                                        label={t(`${MODULE}.unit`)}
                                        variant="outlined"
                                        value={rowData.unit}
                                        className={classes.inputStyle}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={ inputErrors && inputErrors[`trades.${index}.unit`] ? inputErrors[`trades.${index}.unit`][0] : '' }
                                        error={inputErrors && inputErrors[`trades.${index}.unit`] ? true : false}
                                        onChange={(e) => handleInputChange({index:index, fieldName:'unit', value: e.target.value, type:'integer' })}
                                        style={{
                                            margin: 10
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} >
                                <Box sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        type="text"
                                        label={t(`${MODULE}.max_unit`)}
                                        variant="outlined"
                                        className={classes.inputStyle}
                                        value={rowData.max_unit}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={
                                            inputErrors && inputErrors[`trades.${index}.max_unit`]
                                            ? 
                                            inputErrors[`trades.${index}.max_unit`][0]
                                            : 
                                            ''
                                        }
                                        error={inputErrors && inputErrors[`trades.${index}.max_unit`] ? true : false}
                                        onChange={(e) => handleInputChange({index: index, fieldName: 'max_unit', value: e.target.value, type: 'integer'})}
                                        disabled={_.find(trade , tr => tr.price == data[index].price ) ? true : false}
                                        style={{
                                            margin: 10
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} >
                                <Button 
                                size="large" 
                                variant="contained" 
                                style={{ backgroundColor: theme.palette.button.main , margin: 10}} 
                                className={classes.buttonStyle} 
                                onClick={() => removeRow(index)}
                                >
                                    {t(`button.delete`)} <IoClose className={classes.iconStyle} />
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    rightIcon: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "green",
        cursor: 'pointer'
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    },
    leftIcon: {
        position: "absolute",
        left: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    },
    iconStyle: {
        fontSize: 20
    },
    inputStyle: {
        margin: 10
    }
}))